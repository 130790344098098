import React, {useEffect, useState} from 'react';
import {useUser, useUserDispatch} from '../auth/userContext';
import {updateRepairer} from './accountActions';
import FullPageSpinner from '../util/FullPageSpinner';
import styled from 'styled-components';
import colors from '../theme/colors';
import {Button, IconButton, Snackbar, TextField} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import translations from '../reparations/ReparationPricesTranslation';
import {defaultReparationPrices} from '../reparations/defaultReparationPrices';

export default () => {
  const {uid, reparationPrices} = useUser();

  const userDispatch = useUserDispatch();

  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    async function initData() {
      if (!reparationPrices) {
        await updateRepairer(uid, {reparationPrices: defaultReparationPrices});
        userDispatch({reparationPrices: defaultReparationPrices});
      }
    }
    initData();
  }, [reparationPrices, uid, userDispatch]);

  const onInputChange = async (newInput, category, label) => {
    const reparationsCopy = reparationPrices;
    reparationsCopy[category][label] = Number(newInput.target.value);
    try {
      await updateRepairer(uid, {reparationPrices: reparationsCopy});
      userDispatch({reparationPrices: reparationsCopy});
      setToastMessage('Enregistré');
    } catch (e) {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastMessage('');
  };

  if (!reparationPrices) return <FullPageSpinner />;

  return (
    <Container>
      {Object.entries(reparationPrices).map(category => {
        return (
          <Category key={category[0]}>
            <CategoryName>{translations[category[0]]}</CategoryName>
            <br />
            {Object.entries(category[1] as {}).map(p => {
              return (
                <InputAndLabel>
                  <Label>{translations[p[0]]}</Label>
                  <Line />
                  <TextField
                    defaultValue={p[1]}
                    type={'number'}
                    onChange={e => onInputChange(e, category[0], p[0])}
                  />
                </InputAndLabel>
              );
            })}
          </Category>
        );
      })}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message={toastMessage}
        action={
          <>
            <Button color="primary" size="small" onClick={handleClose}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1400px;
  min-width: 850px;
`;

const CategoryName = styled.span`
  font-size: 26px;
  color: ${colors.greyA500};
`;

const Category = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
`;

const InputAndLabel = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const Label = styled.span`
  color: ${colors.greyA500};
  align-self: flex-end;
  font-size: 16px;
  line-height: 14px;
`;

const Line = styled.span`
  color: ${colors.greyA500};
  flex: 1;
  display: flex;
  border-bottom: 1px solid ${colors.greyA200};
  margin-left: 10px;
  margin-right: 10px;
`;
