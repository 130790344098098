import React, {createContext, useEffect, useState} from 'react';
import firebase from 'firebase';

import {getRepairer} from '../account/accountActions';
import FullPageSpinner from '../util/FullPageSpinner';
import useAuth from './authContext';

type Repairer = {
  uid: string;
  openHours?: string;
  calendarSlotDuration?: number;
  reparationDurations?: ReparationDurations;
  reparationPrices?: any;
  appointmentSettings?: any;
  phoneNumber?: string;
  address?: string;
  name: string;
  g: any;
};

export type ReparationDurations = {
  flatTire: number;
  brakes: number;
  gears: number;
  overhaul: number;
  other: number;
  controlBeforeSale: number;
};

type SetUser = (partialUser: any) => void;

const UserContext = createContext({} as Repairer | undefined);
const UserDispatchContext = createContext({} as SetUser);

function UserProvider({children}) {
  const userFromAuth = useAuth();

  const [user, setUserDetails] = useState<Repairer | undefined>(undefined);
  const setUser: SetUser = newData => {
    setUserDetails({...user, ...newData});
  };

  useEffect(() => {
    async function initData() {
      if (userFromAuth) {
        const repairer = await getRepairer(firebase.auth().currentUser!.uid);
        // @ts-ignore
        setUserDetails({
          ...firebase.auth().currentUser,
          ...repairer,
        });
      }
    }

    initData();
  }, [userFromAuth]);

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={setUser}>
        {!user ? <FullPageSpinner /> : children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserContext);

  if (!context) {
    throw new Error('useUserState must be used within a UserProvider');
  }

  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);

  if (!context) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }

  return context;
}

export {UserProvider, useUser, useUserDispatch};
