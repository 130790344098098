import React, {useEffect, useState} from 'react';
import {Button, TextField} from '@material-ui/core';
import styled from 'styled-components';
import {useToasts} from 'react-toast-notifications';
import firebase from 'firebase';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {useParams} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import colors from '../theme/colors';
import GenderSelection from './GenderSelection';
import genders from './genders';
import BikeTypeSelection from './BikeTypeSelection';
import bikeTypes from '../bikes/bikeTypes';
import {createUserBike} from '../bikes/bikeActions';
import {useUser} from '../auth/userContext';
import {
  createSaleCard,
  deactivateSaleCard,
  getSaleCard,
} from './marketPlaceActions';
import Add4Photos from './Add4Photos';
import isDev from '../auth/isDev';
import FullPageSpinner from '../util/FullPageSpinner';
import I18n from '../translations/i18n';
import Stars from '../estimates/Stars';
import electricControls from '../estimates/electricControls';
import mechanicalControls from '../estimates/mechanicalControls';
import {createReparation} from '../reparations/reparationsActions';
import reparationStatutes from '../reparations/reparationStatutes';
import {controlBeforeSale} from '../reparations/problems';
import reparationTypesEnum from '../entriesAndExits/reparationTypesEnum';
import bikeSizes from '../bikes/bikeSizes';

const PublishAdvertisment = () => {
  const {addToast} = useToasts();

  let {saleCardUid} = useParams();

  const me = useUser();

  const [gender, setGender] = useState(genders.woman);
  const [selectedBikeType, setSelectedBikeType] = useState(bikeTypes.city);
  const [isElectric, setIsElectric] = useState(false);
  const [price, setPrice] = useState(isDev ? 80 : null);
  const [title, setTitle] = useState(isDev ? "Titre de l'annonce" : null);
  const [controls, setControls] = useState<any>(mechanicalControls);
  const [size, setSize] = useState<string>(bikeSizes.unknown);
  const [images, setImages] = useState(
    isDev
      ? [
          'https://firebasestorage.googleapis.com/v0/b/biket-62bb2.appspot.com/o/bike.png?alt=media&token=9cd09168-fffa-4146-9ef1-b8fb836ed4fd',
          '',
          '',
          '',
        ]
      : ['', '', '', ''],
  );
  const [description, setDescription] = useState(
    isDev ? 'Un super vélo, rapport qualité/prix excellent' : null,
  );
  const [brand, setBrand] = useState<any>();
  const [identificationNumber, setIdentificationNumber] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!saleCardUid) return;

    async function initData() {
      const saleCard = await getSaleCard(saleCardUid);
      if (!saleCard) return;

      setGender(saleCard.gender);
      setSelectedBikeType(saleCard.bikeType);
      setIsElectric(saleCard.isElectric);
      setPrice(saleCard.price);
      setTitle(saleCard.title);
      setImages(saleCard.images);
      setDescription(saleCard.description);
      setBrand(saleCard.brand);
      setIdentificationNumber(saleCard.identificationNumber);
      setIdentificationNumber(saleCard.size);
    }
    initData();
  }, [saleCardUid]);

  useEffect(() => {
    setControls(isElectric ? electricControls : mechanicalControls);
  }, [isElectric]);

  const changeRating = (newRating, reparationIndex) => {
    setControls(
      controls.map((r, i) => {
        if (i === reparationIndex) return {...r, rating: newRating};
        return r;
      }),
    );
  };

  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const onChangePrice = e => {
    setPrice(e.target.value);
  };

  const onChangeBrand = e => {
    setBrand(e.target.value);
  };

  const onChangeDescription = e => {
    setDescription(e.target.value);
  };

  const onChangeIdentificationNumber = e => {
    setIdentificationNumber(e.target.value);
  };

  const addBikePhoto = (photoUrl, photoIndex) => {
    const photosCopy: any = images;
    photosCopy[photoIndex] = photoUrl;
    setImages([...photosCopy]);
  };

  const sellBike = async () => {
    if (!title) {
      addToast("Veuillez choisir un titre à l'annonce", {
        appearance: 'error',
      });
      return;
    }
    if (!price) {
      addToast("Veuillez choisir un prix à l'annonce", {
        appearance: 'error',
      });
      return;
    }

    if (!description) {
      addToast("Veuillez écrire une description à l'annonce", {
        appearance: 'error',
      });
      return;
    }

    if (!images.filter(p => p).length) {
      return addToast('Veuillez fournir au moins une photo', {
        appearance: 'error',
      });
    }

    setIsLoading(true);

    const user = {
      uid: me.uid,
      address: me.address,
      phoneNumber: me.phoneNumber,
      firstName: me.name,
    };

    const createdBike = await createUserBike(user, {
      isElectric,
      gender,
      bikeType: selectedBikeType,
      name: title,
      images: images,
      isOnSale: true,
      ...(brand && {brand}),
      ...(identificationNumber && {identificationNumber}),
    });

    createReparation({
      bike: createdBike,
      bikeUid: createdBike.uid,
      control: controls,
      status: reparationStatutes.waitingForRemoval,
      controlEstimate: [],
      repairer: user,
      problem: controlBeforeSale,
      isElectric,
      user,
      start: new Date(),
      exitDate: new Date(),
      reparationType: reparationTypesEnum.fromRepairer,
    });

    await createSaleCard({
      isElectric,
      gender,
      bikeType: selectedBikeType,
      coordinates: new firebase.firestore.GeoPoint(
        me.g.geopoint.latitude,
        me.g.geopoint.longitude,
      ),
      description,
      images: images,
      price,
      title,
      user,
      sellerUid: me.uid,
      size,
      userBikeUid: createdBike.uid,
      bike: createdBike,
      ...(brand && {brand}),
      ...(identificationNumber && {identificationNumber}),
    });

    if (saleCardUid) {
      deactivateSaleCard(saleCardUid);
    }

    setGender(genders.woman);
    setSelectedBikeType(bikeTypes.city);
    setIsElectric(false);
    setPrice(isDev ? 80 : null);
    setTitle(isDev ? "Titre de l'annonce" : null);
    setImages(['', '', '', '']);
    setDescription(
      isDev ? 'Un super vélo, rapport qualité/prix excellent' : null,
    );
    setBrand(null);
    setIdentificationNumber(null);
    setControls(mechanicalControls);
    setIsLoading(false);

    addToast(I18n.t('saved'), {appearance: 'success'});
  };

  const handleSizeChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSize(event.target.value as string);
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <MainContainer>
      <Title>Mettre un vélo en vente sur Biket</Title>

      <GenderSelection selectedGender={gender} onSelectGender={setGender} />

      <BikeTypeSelection
        selectedBikeTypes={[selectedBikeType]}
        onSelectBikeType={newBikeType => setSelectedBikeType(newBikeType)}
        isElectric={isElectric}
        setIsElectric={setIsElectric}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: 20,
        }}>
        <STextField
          value={title}
          onChange={onChangeTitle}
          placeholder={"Titre de l'annonce"}
          required
        />

        <STextField
          value={price}
          onChange={onChangePrice}
          placeholder={'Prix'}
          type={'number'}
          required
        />

        <STextField
          value={description}
          onChange={onChangeDescription}
          placeholder={'Description'}
        />

        <STextField
          value={brand}
          onChange={onChangeBrand}
          placeholder={'Marque du vélo'}
        />

        <STextField
          value={identificationNumber}
          onChange={onChangeIdentificationNumber}
          placeholder={"Numéro d'identification du vélo"}
        />

        <SFormControl>
          <SSelect
            labelId="size-label"
            id="size"
            value={size}
            onChange={handleSizeChange}>
            {Object.values(bikeSizes).map(size => {
              return (
                <MenuItem value={size} key={size}>
                  {I18n.t(size)}
                </MenuItem>
              );
            })}

            {/*<MenuItem value={'20'}>Twenty</MenuItem>*/}
            {/*<MenuItem value={'30'}>Thirty</MenuItem>*/}
          </SSelect>
          <FormHelperText>Taille du vélo</FormHelperText>
        </SFormControl>
      </div>

      <Add4Photos
        images={images}
        imagePrefix={'repairerSellBike_' + me.uid}
        onUploadAction={addBikePhoto}
      />

      <ControlContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Contrôle</StyledTableCell>
              <StyledTableCell align="center">Note</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controls.map((control, index) => (
              <StyledTableRow key={control.uid}>
                <StyledTableCell align="center" component="th" scope="row">
                  {control.reparationText}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Stars
                    rating={control.rating}
                    changeRating={newRating => changeRating(newRating, index)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </ControlContainer>

      <ButtonContainer>
        <Button color="primary" onClick={sellBike} variant="contained">
          Mettre en vente
        </Button>
      </ButtonContainer>
    </MainContainer>
  );
};

export default PublishAdvertisment;

const Title = styled.h1`
  color: ${colors.greyA500};
  text-align: center;
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
`;

const STextField = styled(TextField)`
  max-width: 900px;
  width: 100%;
  margin-top: 20px;
`;

const SSelect = styled(Select)`
  max-width: 900px;
  width: 100%;
  margin-top: 20px;
`;

const SFormControl = styled(FormControl)`
  max-width: 900px;
  width: 100%;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ControlContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin-top: 20px;
`;

const StyledTableCell = withStyles(({palette}: Theme) =>
  createStyles({
    head: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);
