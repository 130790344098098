import {createMuiTheme} from '@material-ui/core';
import colors from './colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    grey: {
      A100: colors.greyA100,
      A200: colors.greyA200,
      A400: colors.greyA400,
      A700: colors.greyA700,
    },
    info: {
      main: colors.primary,
    },
    error: {
      main: colors.palette2,
    },
  },
});

export default theme;
