export default (problem, reparationPrices): any => {
  let formattedProblem = problem;

  if (problem === 'flatTire') formattedProblem = 'tires';

  const category = Object.entries(reparationPrices).find(
    r => r[0] === formattedProblem,
  );

  if (!category) return {lines: []};

  return {
    problem,
    category: category[0],
    lines: Object.entries(category[1] as {}) || [],
  };
};
