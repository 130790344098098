import Reparation from "../reparations/Reparation.interface";
import React from "react";

function sortReparationsByExistDate(a: Reparation, b: Reparation) {
  // @ts-ignore because attribut ".exitDate" exist
  return a.exitDate - b.exitDate
}

export function filterReparationForTabsFunctionTemplate(
  reparations: Reparation[],
  setFilteredReparation: React.Dispatch<React.SetStateAction<Reparation[]>>,
  orderByExistDate: boolean = false
) {
  return e => {
    if (e.target && e.target.value !== '') {
      reparations = reparations.filter(b => {
        if (b.user && b.user.firstName && b.user.lastName)
          return (
            b.user.firstName
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) >= 0 ||
            b.user.lastName
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) >= 0
          );
        else
          return false;
      });
    }
    setFilteredReparation((orderByExistDate) ? reparations.sort(sortReparationsByExistDate) : reparations);
  }
}