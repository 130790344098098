import React from 'react';
import styled from 'styled-components';
import {Button, Link} from '@material-ui/core';

export default () => {
  return (
    <Download>
      {/* <div className="anchor"  style={{marginTop: '100px'}}></div> */}
      <h2
        id="download"
        style={{
          fontFamily: "'Lobster', cursive",
          fontSize: '2.5rem',
          margin: 0,
          marginBottom: '40px',
          fontWeight: 400,
          color: '#164a39',
          paddingTop: '110px',
          marginTop: '-110px',
        }}>
        Télécharger l’application Biket&nbsp;:
      </h2>

      <SelectApp>
        <div
          className="android"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <p>Vous êtes plutôt du genre&nbsp;:</p>
          <img
            src={require('../../assets/android.svg')}
            style={{height: '50px', marginBottom: '20px'}}
            alt="Logo Biket"
          />
          <Link
            href="https://play.google.com/store/apps/details?id=com.biket"
            target="_blank">
            <Button
              variant="contained"
              color="secondary"
              style={{color: 'white', textTransform: 'none'}}
              // onClick={() => history.push(routes.login)}
            >
              <strong>Télécharger ici</strong>
            </Button>
          </Link>
        </div>
        <div
          className="iOS"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <p>Vous êtes plutôt du style&nbsp;:</p>
          <img
            src={require('../../assets/apple.svg')}
            style={{height: '50px', marginBottom: '20px'}}
            alt="Logo Biket"
          />

          <Link
            href="https://apps.apple.com/us/app/biket/id1527491487"
            target="_blank">
            <Button
              variant="contained"
              color="secondary"
              style={{color: 'white', textTransform: 'none'}}
              // onClick={() => history.push(routes.login)}
            >
              <strong>Télécharger ici</strong>
            </Button>
          </Link>
        </div>
      </SelectApp>
    </Download>
  );
};

const Download = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding: 20px;
    h2 {
      padding-top: 70px !important;
      margin-top: -70px !important;
    }
    p {
      text-align: center;
    }
  }
`;

const SelectApp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
