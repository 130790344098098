import React, {FunctionComponent, useEffect, useState} from 'react';
import reparationStatutes from '../reparations/reparationStatutes';
import {Button, IconButton, Snackbar, TextField} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import colors from '../theme/colors';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import reparationTypesEnum from '../entriesAndExits/reparationTypesEnum';
import CloseIcon from '@material-ui/icons/Close';
import {updateReparation} from './reparationsActions';
import {sendReparationDoneNotification} from '../notifications/sendNotification';
import {controlBeforeSale} from './problems';
import I18n from '../translations/i18n';
import translations from './ReparationPricesTranslation';
import Reparation from './Reparation.interface';
import ControlEstimateModal from '../estimates/ControlEstimateModal';
import CompletedControlModal from '../estimates/CompletedControlModal';
import {filterReparationForTabsFunctionTemplate} from "../lib/filterReparationForTabsFunctionTemplate";

type ReparationsToCompleteProps = {
  pendingReparations: Reparation[]
}

const ReparationsToComplete: FunctionComponent<ReparationsToCompleteProps> = ({pendingReparations}) => {
  const [filteredPendingReparations, setFilteredPendingReparations] = useState<Reparation[]>(pendingReparations);
  const [toastMessage, setToastMessage] = useState<string>();
  const [isTerminatedControlOpen, setIsTerminatedControlOpen] = useState(false);
  const [control, setControl] = useState<any>();

  const reparationTypeText = entry => {
    if (entry.reparationType === reparationTypesEnum.urgency) return 'Urgence';
    if (entry.reparationType === reparationTypesEnum.appointment)
      return 'Rendez-vous';
    if (entry.reparationType === reparationTypesEnum.immediate)
      return 'Réparation sous 48 heures';
  };

  // Refresh filtered reparations if reparations from props as change
  useEffect(() => setFilteredPendingReparations(pendingReparations), [pendingReparations])

  const markAsRepaired = async entry => {
    if (entry.controlEstimate) {
      setControl({
        control: entry.control,
        uid: entry.uid,
        userUid: entry.user.uid,
      });
      setIsTerminatedControlOpen(true);
    } else {
      try {
        await Promise.all([
          updateReparation(entry.uid, {
            status: reparationStatutes.waitingForRemoval,
            reparationTerminatedAt: new Date(),
          }),
          sendReparationDoneNotification(entry.user.uid, entry),
        ]);
        setToastMessage('Réparation terminée');
      } catch (e) {
        setToastMessage("Une erreur s'est produite, veuillez réessayer");
      }
    }
  };

  const filterByUserName = filterReparationForTabsFunctionTemplate(filteredPendingReparations, setFilteredPendingReparations)

  return (
    <>
      <SearchBar>
        <SSearchIcon />
        <TextField
          label="Filtrer par nom de client"
          onChange={filterByUserName}
          name="filter"
        />
      </SearchBar>

      <CompletedControlModal
        isModalOpened={isTerminatedControlOpen}
        openModal={setIsTerminatedControlOpen}
        control={control}
      />

      {filteredPendingReparations.map((entry, i) => (
        <List dense key={entry.uid}>
          <ListItem style={i % 2 ? {backgroundColor: colors.greyA200} : {}}>
            <ListItemText
              primary={
                entry.problem !== controlBeforeSale
                  ? entry.title
                  : entry.title +
                    ' / ' +
                    I18n.t('optionalProcedures') +
                    ': ' +
                    // @ts-ignore
                    entry.controlEstimate
                      .filter(c => c.isSelectedByClient)
                      .map(c => translations[c.name] || c.name)
                      .join(' / ')
              }
              secondary={moment(
                entry.start
                  ? // @ts-ignore
                    entry.start.toDate()
                  : // @ts-ignore
                    entry.creationDate.toDate(),
              ).format('LLL')}
            />
            <ReparationType>{reparationTypeText(entry)}</ReparationType>
            <Button
              variant="contained"
              color="primary"
              onClick={() => markAsRepaired(entry)}>
              <strong>Réparation terminée</strong>
            </Button>
          </ListItem>
        </List>
      ))}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={() => setToastMessage('')}
        message={toastMessage}
        action={
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => setToastMessage('')}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastMessage('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default ReparationsToComplete

const ReparationType = styled.span`
  color: ${colors.palette3};
  font-weight: bold;
  margin-right: 20px;
`;

const SearchBar = styled.form`
  align-items: end;
  justify-content: center;
  display: flex;
`;

const SSearchIcon = styled(SearchIcon)`
  margin-right: 12px;
  color: ${colors.palette3};
  font-size: 30px;
`;
