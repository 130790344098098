import firebase from 'firebase';
import {initializeApp} from 'geofirestore';

import {repairers} from '../firebase/firestoreCollections';
import Repairer from './Repairer.interface';

export const createRepairer = (repairer: Repairer) => {
  const geoFirestore = initializeApp(firebase.firestore());
  const collection = geoFirestore.collection(repairers);

  return collection.doc(repairer.uid).set(repairer);
};
