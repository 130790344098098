import React, {createContext, useState} from 'react';
import OpenHours, {emptyOpenHours} from './OpenHours';
import {useUser} from '../auth/userContext';

type SetOpenHours = (partialOpenHours: any) => void;

const OpenHoursContext = createContext({} as OpenHours | undefined);
const OpenHoursDispatchContext = createContext({} as SetOpenHours);

function OpenHoursProvider({children}) {
  const {openHours} = useUser();

  const initialOpenHours = openHours ? JSON.parse(openHours) : emptyOpenHours;

  const [newOpenHours, setOpenHoursDetails] = useState<OpenHours>(
    initialOpenHours,
  );

  const setOpenHours: SetOpenHours = newData => {
    setOpenHoursDetails({...newOpenHours, ...newData});
  };

  return (
    <OpenHoursContext.Provider value={newOpenHours}>
      <OpenHoursDispatchContext.Provider value={setOpenHours}>
        {children}
      </OpenHoursDispatchContext.Provider>
    </OpenHoursContext.Provider>
  );
}

function useOpenHours() {
  const context = React.useContext(OpenHoursContext);

  if (!context) {
    throw new Error(
      'useOpenHoursState must be used within a OpenHoursProvider',
    );
  }

  return context;
}

function useOpenHoursDispatch() {
  const context = React.useContext(OpenHoursDispatchContext);

  if (!context) {
    throw new Error(
      'useOpenHoursDispatch must be used within a OpenHoursProvider',
    );
  }

  return context;
}

export {OpenHoursProvider, useOpenHours, useOpenHoursDispatch};
