import React, {useEffect, useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';

import TabPanel from '../util/TabPanel';
import Calendar from './Calendar';
import BikeEntries from '../entriesAndExits/BikeEntries';
import BikeExits from '../entriesAndExits/BikeExits';
import PendingEstimate from '../estimates/PendingEstimate';
import ReparationsToComplete from '../reparations/ReparationsToComplete';
import Reparation from '../reparations/Reparation.interface';
import {useUser} from '../auth/userContext';
import firebase from 'firebase';
import {Badge} from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';

import {
  manualAppointmentsWebsocket,
  reparations as reparationsCollection,
} from '../firebase/firestoreCollections';
import ControlsToComplete from '../control/ControlsToComplete';
import {controlBeforeSale} from '../reparations/problems';
import I18n from '../translations/i18n';
import reparationStatutes from '../reparations/reparationStatutes';
import ManualAppointments from '../manualAppointments/ManualAppointments';
import {Demand} from '../urgencies/urgenciesContext';

export default () => {
  const user = useUser();

  const [tab, setTab] = React.useState(0);
  const [pendingReparations, setPendingReparations] = useState<Reparation[]>(
    [],
  );
  const [entries, setEntries] = useState<Reparation[]>([]);
  const [pendingEstimates, setPendingEstimates] = useState<Reparation[]>([]);
  const [pendingControls, setPendingControls] = useState<Reparation[]>([]);
  const [exits, setExits] = useState<Reparation[]>([]);
  const [manualAppointmentDemands, setManualAppointmentDemands] = useState<any>(
    [],
  );

  // @ts-ignore
  useEffect(() => {
    if (!user?.uid) return;

    return firebase
      .firestore()
      .collection(reparationsCollection)
      .where('repairerUid', '==', user?.uid)
      .onSnapshot(querySnapshot =>
        querySnapshot.docChanges().forEach(change => {
          const data = change.doc.data();

          if (change.type === 'modified') { // Delete modified reparation from states
            const filterReparationByUid = reparation => reparation.filter(r => r.uid !== data.uid);

            setEntries(filterReparationByUid);
            setPendingReparations(filterReparationByUid);
            setPendingControls(filterReparationByUid);
            setPendingEstimates(filterReparationByUid);
            setExits(filterReparationByUid);
          }

          if (
            data.status === reparationStatutes.waitingForRemoval ||
            data.status === reparationStatutes.waitingForReparation ||
            data.status === reparationStatutes.waitingForEstimate ||
            data.status === reparationStatutes.waitingForEstimateValidation ||
            data.status === reparationStatutes.estimateRefused
          ) {
            setExits(r => {
              // To keep the last updated from two controls clones
              // @ts-ignore
              return _.uniqBy(_.reverse(_.clone(r.concat([data]))), 'uid');
            });
          }

          if (data.status === reparationStatutes.bikeNotReceivedYet) {
            if (
              manualAppointmentDemands.map(d => d.demandId).includes(data.uid)
            ) {
              setManualAppointmentDemands(demands => {
                // @ts-ignore
                return demands.filter(demand => demand.demandId !== data.uid);
              });
            }

            setEntries(r => {
              // @ts-ignore
              return _.uniqBy(_.reverse(_.clone(r.concat([data]))), 'uid');
            });
          } else if (
            data.problem === controlBeforeSale &&
            data.status === reparationStatutes.runningControl
          ) {
            setPendingControls(r => {
              // @ts-ignore
              return _.uniqBy(_.reverse(_.clone(r.concat([data]))), 'uid');
            });
          } else if (
            data.problem !== controlBeforeSale &&
            data.status === reparationStatutes.waitingForEstimate
          ) {
            setPendingEstimates(r => {
              // @ts-ignore
              return _.uniqBy(_.reverse(_.clone(r.concat([data]))), 'uid');
            });
          } else if (data.status === reparationStatutes.waitingForReparation) {
            setPendingReparations(r => {
              // @ts-ignore
              return _.uniqBy(_.reverse(_.clone(r.concat([data]))), 'uid');
            });
          }
        }),
      );
  }, [user, manualAppointmentDemands]);

  useEffect(() => {
    async function initData() {
      if (!user?.uid) return;

      return firebase
        .firestore()
        .collection(manualAppointmentsWebsocket)
        .where(
          'creationDate',
          '>',
          new Date(moment().subtract(30, 'minutes').valueOf()),
        )
        .where('repairerUids', 'array-contains', user.uid)
        .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            const data = change.doc.data() as Demand;

            if (data.refusedByRepairerUids?.includes(user.uid)) {
              setManualAppointmentDemands(demands => {
                // @ts-ignore
                return demands.filter(
                  demand => demand.demandId !== data.demandId,
                );
              });
            } else {
              setManualAppointmentDemands(d => {
                // @ts-ignore
                return _.uniqBy(
                  _.reverse(_.clone(d.concat([data]))),
                  'demandId',
                );
              });
            }
          });
        });
    }
    initData();
  }, [user]);

  const tabOffset =
    user?.appointmentSettings?.automaticAppointment === false ? 1 : 0;

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        centered
        onChange={(e, t) => setTab(t)}
        aria-label="tabs">
        <Tab label="Créneaux de réparation" />

        {user?.appointmentSettings?.automaticAppointment === false && (
          <Tab
            label={
              <SBadge
                badgeContent={manualAppointmentDemands.length}
                color="secondary">
                Demandes de RDV
              </SBadge>
            }
          />
        )}

        <Tab
          label={
            <SBadge badgeContent={entries.length} color="secondary">
              Réception de vélos
            </SBadge>
          }
        />

        <Tab
          label={
            <SBadge badgeContent={pendingEstimates.length} color="secondary">
              Devis en attente
            </SBadge>
          }
        />

        <Tab
          label={
            <SBadge badgeContent={pendingControls.length} color="secondary">
              {I18n.t('pendingControl')}
            </SBadge>
          }
        />

        <Tab
          label={
            <SBadge badgeContent={pendingReparations.length} color="secondary">
              <TabText>Réparations en attente</TabText>
            </SBadge>
          }
        />

        <Tab
          label={
            <SBadge
              badgeContent={
                exits.filter(
                  exit =>
                    exit.status === reparationStatutes.waitingForRemoval ||
                    exit.status === reparationStatutes.estimateRefused,
                ).length
              }
              color="secondary">
              Sorties de vélos
            </SBadge>
          }
        />
        {/*import UrgenciesTab from '../urgencies/UrgenciesTab';*/}
        {/*<Tab label="Demandes urgentes" />*/}
      </Tabs>

      <TabPanel value={tab} index={0}>
        <Calendar />
      </TabPanel>

      {user?.appointmentSettings?.automaticAppointment === false && (
        <TabPanel value={tab} index={1}>
          <ManualAppointments demands={manualAppointmentDemands} />
        </TabPanel>
      )}

      <TabPanel value={tab} index={1 + tabOffset}>
        <BikeEntries entries={entries} />
      </TabPanel>

      <TabPanel value={tab} index={2 + tabOffset}>
        <PendingEstimate pendingEstimates={pendingEstimates} />
      </TabPanel>

      <TabPanel value={tab} index={3 + tabOffset}>
        <ControlsToComplete pendingControls={pendingControls} />
      </TabPanel>

      <TabPanel value={tab} index={4 + tabOffset}>
        <ReparationsToComplete pendingReparations={pendingReparations} />
      </TabPanel>

      <TabPanel value={tab} index={5 + tabOffset}>
        <BikeExits exits={exits} />
      </TabPanel>

      {/*<TabPanel value={tab} index={5}>*/}
      {/*  <UrgenciesTab />*/}
      {/*</TabPanel>*/}
    </>
  );
};

const SBadge = styled(Badge)``;

const TabText = styled.span``;
