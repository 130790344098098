import uuid from 'uuid';

export default [
  {
    key: 'overhaul',
    reparationText: 'État général',
    rating: 1,
    uid: uuid.v4(),
  },
  {
    key: 'tires',
    reparationText: 'Pneus',
    rating: 1,
    uid: uuid.v4(),
  },
  {
    key: 'brakes',
    reparationText: 'Freins',
    rating: 1,
    uid: uuid.v4(),
  },
  {
    key: 'lights',
    reparationText: 'Phares',
    rating: 1,
    uid: uuid.v4(),
  },
  {
    key: 'gears',
    reparationText: 'Transmission',
    rating: 1,
    uid: uuid.v4(),
  },
  {
    key: 'battery',
    reparationText: 'Batterie',
    rating: 1,
    uid: uuid.v4(),
  },
];
