import {Button} from '@material-ui/core';
import React from 'react';

export default props => {
  return (
    <Button onClick={props.onClick} variant="contained">
      {props.children}
    </Button>
  );
};
// style={{color: 'white'}}
