import React from 'react';

export default () => {
  return (
    <div style={{margin: 80}}>
      <title>Biket. Politique de confidentialité</title>
      <div style={{margin: 20}}>
        <h1 style={{textAlign: 'center'}}>Conditions génrales d'utilisation</h1>
        <p>
          CONDITIONS GENERALES D’UTILISATION Date de dernière mise à jour : 2
          avril 2021 Article 1 – MENTION LÉGALES Le présent site accessible à
          l’URL www.biket.fr ainsi que l’application Biket disponible sur l’ App
          Store et le Play Store (le « Site »), est édité par : Biket Gros
          Mollets et Temps Long, société par action simplifiée au capital de
          1000 euros, inscrite au R.C.S. de Paris sous le numéro 897839114, dont
          le siège social est situé au 10 rue du Caire, 75002 Paris,
          représenté(e) par David Chavanis dument habilité(e), (Ci-après désigné
          l’« Exploitant »). Le numéro individuel TVA de l’Exploitant est : FR
          14897839114 Le Site est hébergé par la société 1&1 Ionos SARL situé
          7place de la Gare, BP70109/57200 Sarreguemines , tél 0970808911
          L’Exploitant peut être joint au numéro de téléphone suivant 0140139761
          et à l’adresse mail suivante dchavanis@yahoo.fr Article 2 –
          DESCRIPTION DU SERVICE Le Site et l’application disponible sur l’App
          Store et Play Store est mis à la disposition de toute personne
          accédant au site (l’ « Utilisateur ») pour le mettre en relation avec
          des réparateurs professionnels de cycles ou avec d’autres
          utilisateurs. Pour la réparation, la plateforme permet à l’utilisateur
          la prise de rdv chez le professionnel, le suivi de l’intervention
          ainsi que le chargement du détail de l’intervention dans le carnet
          d’entretien du profil du vélo. L’utilisateur peut également voir
          l’offre de cycles mis en vente par le professionnel et leur permettre
          de rentrer en contact. L’Utilisateur peut également mettre en ligne
          des petites annonces pour informer les autres utilisateurs de la vente
          de cycles ou accessoires, il peut communiquer avec eux par messagerie
          et signaler un transfert de propriété du cycle avec un autre
          utilisateur. Le Site (site et application) n’est pas un site marchand
          et ne propose aucune transaction commerciale à distance. L’Utilisateur
          demeure responsable des modalités et des conséquences de son accès au
          Site notamment par l’Internet. Cet accès peut impliquer le paiement de
          frais à des prestataires techniques tels que notamment des
          fournisseurs d’accès à l’Internet, lesquels demeurent à sa charge. En
          outre, l’Utilisateur devra fournir et être entièrement responsable des
          équipements nécessaires afin de se connecter au Site. L’Utilisateur
          reconnait avoir vérifié que la configuration informatique qu’il
          utilise est sécurisée et en état de fonctionnement. Les informations
          et services proposés par le Site sont gratuits, et accessibles 24h/24h
          et 7jours/7 jours, sauf en cas de force majeure, de pannes
          informatiques, d’opérations de maintenance ou de problèmes liés aux
          réseaux de télécommunications. Article 3 – PROPRIÉTÉ INTELLECTUELLE ET
          LICENCE D’UTILISATION DU SITE L’Exploitant est seul titulaire de tous
          les éléments présents sur le Site, notamment et sans limitation, tous
          textes, fichiers, images animées ou non, photographies, vidéos, logos,
          dessins, modèles, logiciels, marques, identité visuelle, base de
          données, structure du Site et tous autres éléments de propriété
          intellectuelle et autres données ou informations (ci-après, les «
          Éléments ») qui sont protégés par les lois et règlements français et
          internationaux relatifs notamment à la propriété intellectuelle. En
          conséquence, aucun des Éléments du Site ne pourra en tout ou partie
          être modifié, reproduit, copié, dupliqué, vendu, revendu, transmis,
          publié, communiqué, distribué, diffusé, représenté, stocké, utilisé,
          loué ou exploité de toute autre manière, à titre gratuit ou onéreux,
          par un Utilisateur ou par un tiers, quel que soient les moyens et/ou
          les supports utilisés, qu’ils soient connus ou inconnus à ce jour,
          sans l’autorisation préalable exprès et écrite de l’Exploitant au cas
          par cas, et l’Utilisateur est seul responsable de toute utilisation
          et/ou exploitation non autorisée. Par ailleurs, il est précisé que
          l’Exploitant n’est pas propriétaire du contenu mis en ligne par les
          Utilisateurs, pour lequel ces derniers demeurent intégralement
          responsables et garantissent l’Exploitant contre tout recours à ce
          titre. Les Utilisateurs accordent à l’Exploitant une licence
          non-exclusive transférable, sous-licenciable, à titre gratuit et
          mondiale pour l’utilisation des contenus de propriété intellectuelle
          qu’ils publient sur le Site, pour toute la durée de protection de ces
          contenus. L’Exploitant se réserve la possibilité de saisir toutes
          voies de droit à l’encontre des personnes qui n’auraient pas respecté
          les interdictions contenues dans le présent article. ARTICLE 4 –
          RESPONSABILITÉ ET GARANTIE L’Exploitant ne peut être tenu pour
          responsable des informations importées, stockées et/ou publiées sur le
          Site par les Utilisateurs. L’Exploitant ne peut être tenu pour
          responsable au titre de toute information publiée par un Utilisateur
          sur le Site et des dommages directs ou indirects que cette utilisation
          pourrait causer à un tiers, l’Utilisateur à l'origine de la
          publication restant seul responsable à ce titre. L’Utilisateur
          reconnaît que les caractéristiques et les contraintes d'Internet ne
          permettent pas de garantir la sécurité, la disponibilité et
          l'intégrité des transmissions de données sur Internet. Ainsi,
          l’Exploitant ne garantit pas que le Site et ses services
          fonctionneront sans interruption ni erreur de fonctionnement. En
          particulier, leur exploitation pourra être momentanément interrompue
          pour cause de maintenance, de mises à jour ou d'améliorations
          techniques, ou pour en faire évoluer le contenu et/ou leur
          présentation. L’Exploitant ne peut être tenu pour responsable de
          l'utilisation qui serait faite du Site et de ses Éléments par les
          Utilisateurs en violation des présentes Conditions Générales
          d’Utilisation et des dommages directs ou indirects que cette
          utilisation pourrait causer à un Utilisateur ou à un tiers. En
          particulier, l’Exploitant ne peut être tenu pour responsable des
          fausses déclarations faites par un Utilisateur et de son comportement
          vis-à-vis des tiers. Dans le cas où la responsabilité de l’Exploitant
          serait recherchée à raison d'un tel comportement d’un de ses
          Utilisateurs, ce dernier s'engage à garantir l’Exploitant contre toute
          condamnation prononcée à son encontre ainsi qu’à rembourser
          l’Exploitant de l’ensemble des frais, notamment les honoraires
          d’avocats, engagés pour sa défense. L’Utilisateur est seul responsable
          de l'intégralité des contenus qu'il met en ligne sur le Site, dont il
          déclare expressément disposer de l'intégralité des droits, et garantit
          à ce titre l’Exploitant qu'il ne met pas en ligne de contenus violant
          des droits tiers, notamment de propriété intellectuelle, ou
          constituant une atteinte aux personnes (notamment diffamation,
          insultes, injures, etc.), au respect de la vie privée, une atteinte à
          l'ordre public et aux bonnes mœurs (notamment, apologie des crimes
          contre l'humanité, incitation à la haine raciale, pornographie
          enfantine, etc.). En cas d'atteinte aux lois en vigueur, aux bonnes
          mœurs ou aux présentes Conditions Générales d'Utilisation,
          l’Exploitant peut exclure de plein droit les Utilisateurs qui se
          seront rendus coupables de telles infractions et supprimer des
          informations et renvois à ces contenus litigieux. L’Exploitant est
          qualifié d’hébergeur s’agissant du contenu mis en ligne par des tiers.
          À ce titre, il est rappelé que l’Exploitant n’a aucune obligation
          générale de surveillance du contenu transmis ou stocké via le Site.
          Dans le cas où la responsabilité de l’Exploitant serait recherchée à
          raison d'un contenu mis en ligne par l’Utilisateur, ce dernier
          s'engage à garantir l’Exploitant contre toute condamnation prononcée à
          son encontre ainsi qu’à rembourser l’Exploitant de l’ensemble des
          frais, notamment les honoraires d’avocats, engagés pour sa défense.
          ARTICLE 5 – DONNÉES PERSONNELLES Pour davantage d’informations
          concernant l’utilisation de données à caractère personnel par
          l’Exploitant, veuillez lire attentivement la Charte sur le respect de
          la vie privée (la « Charte »). Vous pouvez à tout moment consulter
          cette Charte sur le Site. Article 6 – LIENS HYPERTEXTES Les liens
          hypertextes disponibles sur le Site peuvent renvoyer vers des sites
          tiers non édités par l’Exploitant. Ils sont fournis uniquement pour la
          convenance de l’Utilisateur, afin de faciliter l’utilisation des
          ressources disponibles sur l’Internet. Si l’Utilisateur utilise ces
          liens, il quittera le Site et acceptera alors d’utiliser les sites
          tiers à ses risques et périls ou le cas échéant conformément aux
          conditions qui les régissent. L’Utilisateur reconnait que l’Exploitant
          ne contrôle ni ne contribue en aucune manière à l’élaboration des
          conditions d’utilisation et/ou du contenu s’appliquant à ou figurant
          sur ces sites tiers. En conséquence, l’Exploitant ne saurait être tenu
          responsable de quelque façon que ce soit du fait de ces liens
          hypertextes. En outre, l’Utilisateur reconnait que l’Exploitant ne
          saurait cautionner, garantir ou reprendre à son compte tout ou partie
          des conditions d’utilisation et/ou du contenu de ces sites tiers. Le
          Site peut également contenir des liens hypertextes promotionnels et/ou
          bandeaux publicitaires renvoyant vers des sites tiers non édités par
          l’Exploitant. L’Exploitant invite l’Utilisateur à lui signaler tout
          lien hypertexte présent sur le Site qui permettrait d’accéder à un
          site tiers proposant du contenu contraire aux lois et/ou aux bonnes
          mœurs. L’Utilisateur ne pourra pas utiliser et/ou insérer de lien
          hypertexte pointant vers le site sans l’accord écrit et préalable de
          l’Exploitant au cas par cas. ARTICLE 7 – DISPOSITION GÉNÉRALES
          INTEGRALITÉ DE L’ACCORD DES PARTIES Les présentes Conditions Générales
          d’Utilisation constituent un contrat régissant les relations entre
          l’Utilisateur et l’Exploitant. Elles constituent l'intégralité des
          droits et obligations de l’Exploitant et de l’Utilisateur relatifs à
          leur objet. Si une ou plusieurs stipulations des présentes Conditions
          Générales d’Utilisation étaient déclarées nulles en application d'une
          loi, d'un règlement ou à la suite d'une décision définitive d'une
          juridiction compétente, les autres stipulations garderont toute leur
          force et leur portée. En outre, le fait pour une des parties aux
          présentes Conditions Générales d’Utilisation de ne pas se prévaloir
          d'un manquement de l'autre partie à l'une quelconque des dispositions
          des présentes Conditions Générales d’Utilisation ne saurait
          s'interpréter comme une renonciation de sa part à se prévaloir dans
          l'avenir d'un tel manquement. MODIFICATIONS DES CONDITIONS
          D'UTILISATION L’Exploitant se réserve le droit de modifier à tout
          moment et sans préavis le contenu du Site ou des services qui y sont
          disponibles, et/ou de cesser de manière temporaire ou définitive
          d’exploiter tout ou partie du Site. En outre, l’Exploitant se réserve
          le droit de modifier à tout moment et sans préavis la localisation du
          Site sur l’Internet, ainsi que les présentes Conditions Générales
          d’Utilisation. L’Utilisateur est donc tenu par conséquent de se
          reporter aux présentes Conditions Générales d’Utilisation avant toute
          utilisation du Site. En cas de modifications matérielles,
          l’Utilisateur sera informé au moyen d'un email et d’un avertissement
          sur le Site avant la mise en application de la modification.
          L’Utilisateur reconnait que l’Exploitant ne saurait être tenu
          responsable de quelque manière que ce soit envers lui ou tout tiers du
          fait de ces modifications, suspensions ou cessations. RÉCLAMATION -
          MÉDIATION En cas de litige, vous devez vous adresser en priorité au
          service client de l'entreprise aux coordonnées suivantes : Biket Gros
          Mollets et Temps Longs 10 rue du Caire 75002 Paris dchavanis@yahoo.fr
          En cas d'échec de la demande de réclamation auprès du service client
          ou en l'absence de réponse de ce service dans un délai de 30 jours le
          Client peut soumettre le différend relatif au bon de commande ou aux
          présentes CGV l'opposant à l’Exploitant à la commission de la
          médiation de la consommation. Le médiateur tentera, en toute
          indépendance et impartialité, de rapprocher les parties en vue
          d'aboutir à une solution amiable. Les parties restent libres
          d'accepter ou de refuser le recours à la médiation ainsi que, en cas
          de recours à la médiation, d'accepter ou de refuser la solution
          proposée par le médiateur. DROIT APPLICABLE Ces Conditions Générales
          d’Utilisation sont régies, interprétées et appliquées conformément au
          droit français. ACCEPTATION DES CONDITIONS GÉNÉRALES PAR L’UTILISATEUR
          L’Utilisateur reconnait avoir lu attentivement les présentes
          Conditions Générales d’Utilisation. En s’inscrivant sur le Site ou
          l’application Biket disponible sur l’App Store et Play Store,
          l’Utilisateur confirme avoir pris connaissance des Conditions
          Générales d'Utilisation et les accepter, le rendant contractuellement
          lié par les termes des présentes Conditions Générales d’Utilisation.
          Les Conditions Générales d’Utilisation applicables à l’Utilisateur
          sont celles mises en ligne sur le Site. En cas de modification,
          l’Exploitant publiera ces changements sur le Site pour que
          l’Utilisateur sache quelles informations sont collectées, comment
          elles sont utilisées, dans quelles circonstances, et le cas échéant,
          comment elles sont divulguées. En cas de modifications matérielles,
          l’Utilisateur sera informé au moyen d'un email et d’un avertissement
          sur le Site avant la mise en application de la modification.
          <br />
        </p>
      </div>
    </div>
  );
};
