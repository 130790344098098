import {useToasts} from 'react-toast-notifications';

import {useUser} from '../auth/userContext';
import {getRepairer, updateRepairer} from './accountActions';
import I18n from '../translations/i18n';
import {Switch, TextField} from '@material-ui/core';
import Button from '../components/Button';
import React, {useEffect, useState} from 'react';
import FullPageSpinner from '../util/FullPageSpinner';
import UploadImage from '../uploadImage/UploadImage';
import styled from 'styled-components';
import colors from '../theme/colors';

export default () => {
  const {addToast} = useToasts();
  const {uid} = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState();
  const [shopImage, setShopImage] = useState('');
  const [isFlowActive, setIsFlowActive] = useState<boolean>();
  const [emailNotificationEnabled, setEmailNotificationEnabled] = useState<boolean>();

  const onChangeNumber = event => {
    setPhoneNumber(event.target.value);
  };
  const onChangeAddress = event => {
    setAddress(event.target.value);
  };
  const onChangeFlow = event => {
    setIsFlowActive(event.target.checked);
  };
  const onChangeEmailNotification = event => {
    setEmailNotificationEnabled(event.target.checked);
  };

  useEffect(() => {
    async function initData() {
      const repairer = await getRepairer(uid);
      setAddress(repairer!.address);
      setPhoneNumber(repairer!.phoneNumber);
      setShopImage(repairer!.shopImage);
      setIsLoading(false);

      console.log(repairer);


      if (repairer?.isFlowActive === undefined) {
        await updateRepairer(uid, {isFlowActive: true});
        setIsFlowActive(true);
      } else setIsFlowActive(repairer!.isFlowActive);
      if (repairer?.emailNotificationEnabled === undefined) {
        await updateRepairer(uid, {emailNotificationEnabled: true});
        setEmailNotificationEnabled(true);
      } else setEmailNotificationEnabled(repairer!.emailNotificationEnabled);
    }
    initData();
  }, [uid]);

  const saveNewSettings = async (address, phoneNumber, isFlowActive, emailNotificationEnabled) => {
    try {
      await updateRepairer(uid, {address, phoneNumber, isFlowActive, emailNotificationEnabled});
      addToast(I18n.t('saved'), {appearance: 'success'});
    } catch (e) {
      addToast(I18n.t('errorOccurred'), {
        appearance: 'error',
      });
    }
  };

  const onShopImageUpload = async photoUrl => {
    try {
      await updateRepairer(uid, {shopImage: photoUrl});
      setShopImage(photoUrl);
      addToast(I18n.t('saved'), {appearance: 'success'});
    } catch (e) {
      addToast(I18n.t('errorOccurred'), {
        appearance: 'error',
      });
    }
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <div
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
        }}
        onSubmit={event => {
          event.preventDefault();
          saveNewSettings(address, phoneNumber, isFlowActive, emailNotificationEnabled);
        }}>
        <TextField
          autoFocus
          placeholder={I18n.t('address')}
          variant="outlined"
          type="string"
          margin="normal"
          onChange={onChangeAddress}
          value={address}
          style={{minWidth: 400}}
        />
        <TextField
          variant="outlined"
          placeholder={I18n.t('phoneNumber')}
          margin="normal"
          onChange={onChangeNumber}
          value={phoneNumber}
          style={{minWidth: 400}}
        />

        <LabelAndSwitch>
          <SwitchLabel>J'utilise Biket pour gérer mes RDV</SwitchLabel>
          <Switch
            checked={isFlowActive ?? false}
            onChange={onChangeFlow}
            color="primary"
          />
        </LabelAndSwitch>
        <LabelAndSwitch>
          <SwitchLabel>Je souhaite recevoir des notifications par email</SwitchLabel>
          <Switch
            checked={emailNotificationEnabled ?? false}
            onChange={onChangeEmailNotification}
            color="primary"
          />
        </LabelAndSwitch>

        <Button disabled={!address && !phoneNumber}>{I18n.t('submit')}</Button>
      </form>

      <div style={{marginTop: 40}}>
        <Bold>Image de mon magasin sur l'application Biket :</Bold>
        <UploadImage
          images={[shopImage]}
          imagePrefix={'repairerShopImage_' + uid}
          onUploadAction={onShopImageUpload}
        />
      </div>
    </div>
  );
};

const Bold = styled.span`
  font-family: Termina-Bold;
  font-weight: bold;
  font-size: 16px;
`;

const LabelAndSwitch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
`;

const SwitchLabel = styled.p`
  max-width: 850px;
  color: ${colors.greyA500};
`;
