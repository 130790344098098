import React, {FunctionComponent, useEffect, useState} from 'react';

import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from '@material-ui/core';

import colors from '../theme/colors';
import {useUser} from '../auth/userContext';
import {getCalendar, saveCalendar} from '../calendar/calendarActions';
import reparationStatutes from '../reparations/reparationStatutes';
import reparationTypesEnum from './reparationTypesEnum';
import {updateReparation} from '../reparations/reparationsActions';
import CloseIcon from '@material-ui/icons/Close';
import {createUserBike} from '../bikes/bikeActions';
import {filterReparationForTabsFunctionTemplate} from "../lib/filterReparationForTabsFunctionTemplate";
import Reparation from "../reparations/Reparation.interface";

type BikeExitsProps = {
  exits: Reparation[]
}

const BikeExits: FunctionComponent<BikeExitsProps> = ({exits}) => {
  const {uid} = useUser();

  const [filteredBikeExits, setFilteredBikeExits] = useState<any>(
    // @ts-ignore Todo : check if this sort is legit (because .exitDate can be null)
    exits.sort((a, b) => a.exitDate - b.exitDate),
  );
  const [exitToValidate, setExitToValidate] = useState<any>();
  const [code, setCode] = useState<number | null>();
  const [toastMessage, setToastMessage] = useState<string>();

  // Refresh filtered reparations if reparations from props as change
  useEffect(() => setFilteredBikeExits(exits), [exits])

  const reparationTypeText = exit => {
    if (exit.reparationType === reparationTypesEnum.urgency) return 'Urgence';
    if (exit.reparationType === reparationTypesEnum.appointment)
      return 'Rendez-vous';
    if (exit.reparationType === reparationTypesEnum.immediate)
      return 'Réparation sous 48 heures';
  };

  const reparationStatusText = exit => {
    if (exit.status === reparationStatutes.waitingForRemoval)
      return 'Prêt à être récupéré';
    if (exit.status === reparationStatutes.waitingForReparation) {
      return 'À réparer';
    }
    if (exit.status === reparationStatutes.waitingForEstimate) {
      return 'En attente de devis';
    }
    if (exit.status === reparationStatutes.waitingForEstimateValidation) {
      return 'En attente de validation du devis';
    }
    if (exit.status === reparationStatutes.estimateRefused) {
      return 'Devis refusé par le client';
    }
  };

  const updateReparationAndCreateBikeIfNotExist = async exit => {
    if (!exit.bike?.uid) {
      const bike = await createUserBike(exit.user, {
        isElectric: exit.isElectric,
      });

      return updateReparation(exit.uid, {
        status: reparationStatutes.tookBackByOwner,
        bikeTookByByOwnerAt: new Date(),
        bike,
        bikeUid: bike.uid,
      });
    } else {
      return updateReparation(exit.uid, {
        status: reparationStatutes.tookBackByOwner,
        bikeTookByByOwnerAt: new Date(),
      });
    }
  };

  const markAsTookBackByOwner = async exit => {
    const events = await getCalendar(uid);

    const updatedEvent = {
      ...exit,
      resource: {
        ...exit.resource,
        status: reparationStatutes.tookBackByOwner,
      },
    };

    const eventIndex = events
      .map(e => e.resource.eventUid)
      .indexOf(exit.eventUid);

    events[eventIndex] = updatedEvent;

    await Promise.all([
      saveCalendar({events, repairerUid: uid}),
      updateReparationAndCreateBikeIfNotExist(exit),
    ]);
  };

  const filterExits = filterReparationForTabsFunctionTemplate(filteredBikeExits, setFilteredBikeExits, true)

  const checkCode = async (enteredCode, realCode) => {
    try {
      const code = Number(enteredCode.target.value);
      setCode(code);
      if (code === realCode) {
        await markAsTookBackByOwner(exitToValidate);
        setExitToValidate(null);
        setToastMessage('Code correct, vélo marqué comme récupéré');
      }
    } catch {
    }
  };

  return (
    <>
      <SearchBar>
        <SSearchIcon/>
        <TextField
          label="Filtrer par nom de client"
          onChange={filterExits}
          name="filter"
        />
      </SearchBar>

      <List dense>
        {filteredBikeExits.map((exit, i) => (
          <ListItem
            style={i % 2 ? {backgroundColor: colors.greyA100} : {}}
            key={exit.uid}>
            <ListItemText
              primary={
                exit.title +
                ' -- ' +
                _.upperFirst(exit.user.firstName) +
                ' ' +
                _.upperFirst(exit.user.lastName)
              }
              secondary={
                'Sortie prévue : ' +
                moment(exit.exitDate?.toDate()).format('LLL')
              }
            />

            <ReparationType>{reparationTypeText(exit)}</ReparationType>
            <ReparationStatus>{reparationStatusText(exit)}</ReparationStatus>
            {(exit.status === reparationStatutes.waitingForRemoval ||
              exit.status === reparationStatutes.estimateRefused) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setExitToValidate(exit)}>
                <strong>Marquer récupéré</strong>
              </Button>
            )}
          </ListItem>
        ))}
      </List>

      {exitToValidate && (
        <Dialog
          open={true}
          onClose={() => setExitToValidate(null)}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Réparation</DialogTitle>

          <DialogContent style={{alignItems: 'center'}}>
            <DialogContentText>
              Veuillez entrer le code à 4 chiffres que vous fournit le client :
            </DialogContentText>
            <InputContainer>
              <TextField
                defaultValue={''}
                onChange={code => {
                  checkCode(code, exitToValidate.bikeExitCode);
                }}
                autoFocus
                margin="dense"
                id="code"
                label="Code"
                type="number"
                size="small"
              />
            </InputContainer>
          </DialogContent>

          <DialogActions style={{justifyContent: 'space-between'}}>
            <div>
              <Button onClick={() => setExitToValidate(null)} color="primary">
                Annuler
              </Button>
              <Button
                onClick={() => {
                  if (code === exitToValidate.bikeExitCode) {
                    return markAsTookBackByOwner(exitToValidate);
                  } else {
                    setToastMessage('Code erroné, veuillez réessayer');
                  }
                }}
                color="primary">
                Valider
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={() => setToastMessage('')}
        message={toastMessage}
        action={
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => setToastMessage('')}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastMessage('')}>
              <CloseIcon fontSize="small"/>
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default BikeExits;

const ReparationType = styled.span`
  color: ${colors.palette4};
  font-weight: bold;
  margin-right: 20px;
  font-size: 14px;
`;

const ReparationStatus = styled(ReparationType)`
  color: ${colors.palette3};
`;

const SearchBar = styled.form`
  align-items: end;
  justify-content: center;
  display: flex;
`;

const SSearchIcon = styled(SearchIcon)`
  margin-right: 12px;
  color: ${colors.palette3};
  font-size: 30px;
`;

const InputContainer = styled.div`
  justify-content: center;
  display: flex;
`;
