import React from 'react';
import styled from 'styled-components';
import {Switch} from '@material-ui/core';

import I18n from '../translations/i18n';

export default ({isElectric, setIsElectric}) => {
  return (
    <SwitchContainer>
      {isElectric ? (
        <span style={{marginBottom: 4}}>mécanique</span>
      ) : (
        <Bold style={{marginBottom: 4}}>mécanique</Bold>
      )}
      <Switch
        value={isElectric}
        onChange={() => {
          setIsElectric(!isElectric);
        }}
        color={'primary'}
      />

      {isElectric ? (
        <Bold style={{marginTop: 4}}>{I18n.t('electricalAssistance')}</Bold>
      ) : (
        <span style={{marginTop: 4}}>{I18n.t('electricalAssistance')}</span>
      )}
    </SwitchContainer>
  );
};

const Bold = styled.span`
  font-family: Termina-Bold;
  font-size: 16px;
  font-weight: bold;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;
