import firebase from 'firebase';
import uuid from 'uuid';

import {usersBikes} from '../firebase/firestoreCollections';

export const updateBike = async (bikeId, data) => {
  return firebase.firestore().collection(usersBikes).doc(bikeId).update(data);
};

export const createUserBike = async (user, bike) => {
  const uid = uuid.v4();

  const creationDate = new Date();

  const newBike = {
    uid,
    userUid: user.uid,
    creationDate,
    history: [{date: creationDate, user}],
    isOnSale: false,
    isElectric: false,
    registered: true,
    images: [
      'https://firebasestorage.googleapis.com/v0/b/biket-62bb2.appspot.com/o/bike.png?alt=media&token=9cd09168-fffa-4146-9ef1-b8fb836ed4fd',
    ],
    ...bike,
  };

  await firebase.firestore().collection(usersBikes).doc(uid).set(newBike);

  return newBike;
};
