import React from 'react';
import styled from 'styled-components';

export default () => {
  return (
    <Header>
      <Left>
        <h1>
          <img
            src={require('../../assets/biket_white.svg')}
            style={{height: '100px'}}
            alt="Logo Biket"
          />
        </h1>
        <h2
          style={{
            fontFamily: "'Lobster', cursive",
            fontSize: '2.5rem',
            margin: 0,
            marginBottom: '40px',
            fontWeight: 400,
          }}>
          L’App’ du vélo d’occasion&nbsp;!
        </h2>
        <div>
          <p
            style={{
              textAlign: 'center',
              fontWeight: 700,
              fontSize: '1.8rem',
              margin: 0,
            }}>
            Réparer, entretenir, contrôler,
            <br />
            vendre ou acheter
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.5rem',
              margin: 0,
            }}>
            vos vélos d’occasion certifiés tout près de chez vous&nbsp;!
          </p>
        </div>
      </Left>
      <Right />
    </Header>
  );
};
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 490px;
  color: white;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;
const Right = styled.div`
  background-image: url(${require('../../assets/header.png')});
  background-size: cover;
  background-position: right;
  height: 490px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Left = styled.div`
  background-color: #164a39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 490px;
  width: 100%;
  @media (max-width: 768px) {
    height: auto;
    padding: 20px 10px 10vh 10px;
    h2 {
      text-align: center;
    }
  }
`;
