const reparationStatutes = Object.freeze({
  waitingMatchingRepairers: 'waitingMatchingRepairers',
  waitingRepairersAcceptation: 'waitingRepairersAcceptation',
  bikeNotReceivedYet: 'bikeNotReceivedYet',
  runningControl: 'runningControl',
  waitingForEstimate: 'waitingForEstimate',
  waitingForEstimateValidation: 'waitingForEstimateValidation',
  estimateRefused: 'estimateRefused',
  waitingForReparation: 'waitingForReparation',
  waitingForRemoval: 'waitingForRemoval',
  tookBackByOwner: 'tookBackByOwner',
});

export default reparationStatutes;
