import {Button} from '@material-ui/core';
import React from 'react';

export default props => {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled ?? false}
      variant="contained"
      type="submit"
      color="primary"
      style={{color: 'white'}}>
      {props.children}
    </Button>
  );
};
