import React from 'react';
import styled from 'styled-components';
const networkImages = [
  {
    img: 'image 19.png',
    style: {
      width: '200%',
      gridColumn: '4 / 5',
      gridRow: '1',
    },
  },
  {
    img: 'image 18.png',
    style: {
      width: '100%',
      gridColumn: '4',
      gridRow: '2',
    },
  },
  {
    img: 'image 6.png',
    style: {
      width: '100%',
      gridColumn: '5',
      gridRow: '2',
    },
  },
  {
    img: 'image 13.png',
    style: {
      width: '200%',
      gridColumn: '1 / 2',
      gridRow: '3 / 5',
    },
  },
  {
    img: 'image 7.png',
    style: {
      width: '100%',
      gridColumn: '3',
      gridRow: '3',
    },
  },
  {
    img: 'image 10.png',
    style: {
      width: '100%',
      gridColumn: '4',
      gridRow: '3',
    },
  },
  {
    img: 'image 9.png',
    style: {
      width: '100%',
      gridColumn: '5',
      gridRow: '3',
    },
  },
  {
    img: 'image 15.png',
    style: {
      width: '100%',
      gridColumn: '3',
      gridRow: '4',
    },
  },
  {
    img: 'image 17.png',
    style: {
      width: '100%',
      gridColumn: '4',
      gridRow: '4',
    },
  },
  {
    img: 'image 16.png',
    style: {
      width: '100%',
      gridColumn: '5',
      gridRow: '4',
    },
  },
];

export default () => {
  return (
    <Network>
      <h2 id="network" style={{paddingTop: '100px', marginTop: '-100px'}}></h2>
      <div
        className="network-header"
        style={{
          gridColumn: '1 / 4',
          gridRow: '1 / 3',
          backgroundColor: '#164a39',
          paddingLeft: '30%',
          paddingRight: '2rem',
          color: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}>
        <h3 style={{fontSize: '1.5rem', fontWeight: 700}}>
          Un réseau de réparateurs partenaires passionnés au service d’une
          communauté concernée.
        </h3>
        <h4 style={{fontSize: '1.2rem', fontWeight: 700}}>
          Le vélo&nbsp;? Bien plus qu’un simple moyen de mobilité&nbsp;: <br />
          un véritable Art de vivre pour les “Biketers”&nbsp;!
        </h4>
        <p style={{fontSize: '1rem', fontWeight: 700}}>
          Choyer son vélo pour qu’il dure dans le temps, être attentif à
          l’environnement et aux autres, s’entourer d’un réseau de
          professionnels sérieux à proximité de chez soi, partager sa passion...
          C’est tout ça Biket&nbsp;!
        </p>
        <h2
          style={{
            fontFamily: "'Lobster', cursive",
            fontSize: '2.5rem',
            fontWeight: 400,
            margin: 0,
            marginBottom: '40px',
          }}>
          Rejoignez la Communauté&nbsp;!
        </h2>
      </div>
      {networkImages.map(image => {
        return (
          <img
            src={require(`../../assets/network/${image.img}`)}
            alt=""
            style={image.style}
          />
        );
      })}
    </Network>
  );
};
const Network = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 20vw);
  // grid-auto-rows: minmax(180px, auto);
  margin-top: 5rem;
  @media (max-width: 768px) {
    display: flex;
    h2 {
      padding-top: 60px !important;
      margin-top: -60px !important;
    }
    .network-header {
      padding-left: 5vw !important;
      text-align: center;
      h3 {
        text-align: center;
      }
    }
    img {
      display: none;
    }
  }
`;
