import React, {createContext, useState} from 'react';

type SetWebSockets = (partialWebSockets: any) => void;

const UrgenciesContext = createContext({} as WebSockets | undefined);
const WebSocketsDispatchContext = createContext({} as SetWebSockets);

export interface Demand {
  demandId: string;
  creationDate: Date | any;
  appointmentDate: Date | any;
  acceptedByMe?: Boolean;
  acceptedByCustomer?: Boolean;
  acceptedByRepairerUids?: string[];
  refusedByRepairerUids?: string[];
  photos?: any[];
  bikeImage?: string;
  // userUid: string;
  problem: string;
}

export default interface WebSockets {
  currentDemands: Demand[];
}

function UrgenciesProvider({children}) {
  const [urgencies, setUrgencies] = useState<WebSockets>({
    currentDemands: [],
  });

  const setNewWebSockets = newData => {
    if (newData.acceptDemand) {
      const currentDemand = urgencies.currentDemands.find(
        d => d.demandId === newData.acceptDemand,
      ) as Demand;
      const otherDemands = urgencies.currentDemands.filter(
        d => d.demandId !== newData.acceptDemand,
      );

      const newCurrentDemands = otherDemands.concat({
        ...currentDemand,
        acceptedByMe: true,
      });

      setUrgencies({
        ...urgencies,
        currentDemands: newCurrentDemands,
      });
    } else if (newData.refuseDemand) {
      const currentDemand = urgencies.currentDemands.find(
        d => d.demandId === newData.acceptDemand,
      ) as Demand;
      const otherDemands = urgencies.currentDemands.filter(
        d => d.demandId !== newData.acceptDemand,
      );

      const newCurrentDemands = otherDemands.concat({
        ...currentDemand,
        acceptedByMe: false,
      });

      setUrgencies({
        ...urgencies,
        currentDemands: newCurrentDemands,
      });
    } else if (newData.chosenByCustomer) {
      const otherDemands = urgencies.currentDemands.filter(
        d => d.demandId !== newData.demandId,
      );

      const newCurrentDemands = otherDemands.concat(newData);

      setUrgencies({
        ...urgencies,
        currentDemands: newCurrentDemands,
      });
    } else if (newData.chosenByCustomer === false) {
      const otherDemands = urgencies.currentDemands.filter(
        d => d.demandId !== newData.demandId,
      );

      const newCurrentDemands = otherDemands.concat(newData);

      setUrgencies({
        ...urgencies,
        currentDemands: newCurrentDemands,
      });
    } else if (newData.newDemand) {
      setUrgencies({
        ...urgencies,
        currentDemands: urgencies.currentDemands.concat(newData),
      });
    } else {
      console.log('Not OKAY');
    }
  };

  return (
    <UrgenciesContext.Provider value={urgencies}>
      <WebSocketsDispatchContext.Provider value={setNewWebSockets}>
        {children}
      </WebSocketsDispatchContext.Provider>
    </UrgenciesContext.Provider>
  );
}

function useWebSockets() {
  const context = React.useContext(UrgenciesContext);

  if (!context) {
    throw new Error(
      'useWebSocketsState must be used within a WebSocketsProvider',
    );
  }

  return context;
}

function useWebSocketsDispatch() {
  const context = React.useContext(WebSocketsDispatchContext);

  if (!context) {
    throw new Error(
      'useWebSocketsDispatch must be used within a WebSocketsProvider',
    );
  }

  return context;
}

export {UrgenciesProvider, useWebSockets, useWebSocketsDispatch};
