import _ from 'lodash';

export const dayOff = [[0, 0], [0, 0]];

export const emptyOpenHours: OpenHours = {
  0: dayOff,
  1: dayOff,
  2: dayOff,
  3: dayOff,
  4: dayOff,
  5: dayOff,
  6: dayOff,
};

export default interface OpenHours {
  0: number[][] | null;
  1: number[][] | null;
  2: number[][] | null;
  3: number[][] | null;
  4: number[][] | null;
  5: number[][] | null;
  6: number[][] | null;
}

export const isCloseDay = (day: number, openHours?: OpenHours) => {
  if (!openHours) return false;
  return _.isEqual(openHours[day], dayOff);
};

export interface TimeRangeSelectorProps {
  dayNumber: number;
  isMorning?: boolean;
  initialStart: string;
  initialEnd: string;
}
