import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import moment from 'moment';
import firebase from 'firebase';
import uuid from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

import {useWebSocketsDispatch} from './urgenciesContext';
import {urgenciesWebsocket} from '../firebase/firestoreCollections';
import {useUser} from '../auth/userContext';
import {acceptDemand as acceptDemandAction} from './urgencyActions';
import problems from '../reparations/problems';

export default () => {
  const {uid} = useUser();

  const dispatch = useWebSocketsDispatch();

  const [modalDemand, setModalDemand] = useState<any>();
  const [toastMessage, setToastMessage] = useState('');

  const showModalIfNotAlready = demand => {
    if (!modalDemand) {
      setModalDemand(demand);
    }
  };

  useEffect(() => {
    return firebase
      .firestore()
      .collection(urgenciesWebsocket)
      .where(
        'creationDate',
        '>',
        new Date(moment().subtract(5, 'minutes').valueOf()),
      )
      .onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          const data = change.doc.data();

          const hasAlreadyBeenRefused = localStorage.getItem(
            'refused_' + data.demandId,
          );

          if (hasAlreadyBeenRefused) return;

          const chosenByUserRepairerUid = data.chosenByUserRepairerUid;

          if (chosenByUserRepairerUid) {
            const hasAlreadyBeenShown = localStorage.getItem(
              'clientChoice_' + data.demandId,
            );
            if (hasAlreadyBeenShown) return;

            if (chosenByUserRepairerUid !== uid) {
              new Notification(
                'Le client a préféré choisir un réparateur plus proche de lui',
              );
              dispatch({...data, chosenByCustomer: false});
            } else {
              new Notification(
                "Le client a contre-validé votre proposition, il devrait arriver d'un moment à l'autre",
              );
              dispatch({...data, chosenByCustomer: true});
            }
            localStorage.setItem(
              'clientChoice_' + data.demandId,
              data.demandId,
            );
          } else {
            const hasAlreadyBeenAccepted = localStorage.getItem(
              'accepted_' + data.demandId,
            );

            const hasDemandToBeShown =
              data.repairerUids &&
              data.repairerUids.indexOf(uid) >= 0 &&
              data.acceptedByRepairerUids.indexOf(uid) === -1 &&
              !hasAlreadyBeenAccepted;

            if (hasDemandToBeShown) {
              new Notification('Nouvelle demande de réparation en urgence');

              dispatch({newDemand: true, ...data});

              showModalIfNotAlready(data);
            }
          }
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const acceptDemand = async () => {
    try {
      await acceptDemandAction(modalDemand.demandId, uid);
      localStorage.setItem(
        'accepted_' + modalDemand.demandId,
        modalDemand.demandId,
      );
      setToastMessage('Demande acceptée');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }

    dispatch({acceptDemand: modalDemand.demandId});
    setModalDemand(null);
  };

  const refuseDemand = () => {
    localStorage.setItem(
      'refused_' + modalDemand.demandId,
      modalDemand.demandId,
    );
    dispatch({refuseDemand: modalDemand.demandId});
    setToastMessage('Demande refusée');
    setModalDemand(null);
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        open={Boolean(modalDemand)}
        onClose={() => setModalDemand(null)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Réparation</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {modalDemand && problems[modalDemand.problem]}
          </DialogContentText>

          {modalDemand?.bikeImage && (
            <div>
              <div>Photo du vélo :</div>
              <SImg src={modalDemand.bikeImage} alt="bike photo" />
            </div>
          )}

          {modalDemand?.photos?.length && (
            <div>
              <div>Photos de la panne :</div>
              {modalDemand.photos.map(photo => {
                return <SImg src={photo} alt="bike photo" key={uuid.v4()} />;
              })}
            </div>
          )}
        </DialogContent>

        <DialogActions style={{justifyContent: 'space-between'}}>
          <div>
            <Button onClick={refuseDemand} color="primary">
              Refuser
            </Button>
            <Button onClick={acceptDemand} color="primary">
              Accepter
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={() => setToastMessage('')}
        message={toastMessage}
        action={
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => setToastMessage('')}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastMessage('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

const SImg = styled.img`
  height: 250px;
`;
