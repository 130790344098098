import React from 'react';
import {Helmet} from 'react-helmet';
import {Button} from '@material-ui/core';
import {GetApp, Group, Eco, Mail} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import routes from '../routes';

import Header from './Header';
import Silder from './Slider';
import Network from './Network';
import Download from './Download';
import Valors from './Valors';
import Footer from './Footer';

import {ReactComponent as ValorsIcon} from '../../assets/valors.svg'

export default () => {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Biket</title>
        <meta name="description" content="Biket" />
      </Helmet>

      <MainContainer>
        <Navbar>
          <Logo />
          <Menu>
            <a href="#download">L'app</a>
            <a href="#valors">Les valeurs Biket</a>
            <a href="#network">La communauté</a>
            <a href="mailto:David Chavanis<david@biket.fr>?subject=[Contact Biket]">Contact</a>
          </Menu>
          <MenuMobile>
            <a href="#download">
              <GetApp color="primary" />
            </a>
            <a href="#network">
              <Group color="primary" />
            </a>
            <a href="#valors">
              <ValorsIcon />
            </a>
            <a href="mailto:david@biket.fr">
              <Mail color="primary" />
            </a>
          </MenuMobile>

          <CallToActions>
            <Button
              variant="contained"
              style={{color: 'white', backgroundColor: '#FF7800'}}>
              <a
                href="#download"
                style={{
                  color: '#FFFFFF',
                  textDecoration: 'none',
                  fontWeight: 700,
                }}>
                Je prends rendez-vous
              </a>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{color: 'white'}}
              onClick={() => history.push(routes.login)}>
              <strong>Je suis réparateur</strong>
            </Button>
          </CallToActions>
        </Navbar>
        <Header />
        <Download />
        <Silder />
        <Valors />
        <Network />
        <Footer />
      </MainContainer>
    </>
  );
};

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 2rem;
  margin: 0;
  padding: 0;
  font-family: 'Termina';
  a {
    color: #333333;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

const MenuMobile = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 1rem;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const Logo = styled.div`
  width: 125px;
  height: 60px;
  background-image: url(${require('../../assets/logo_biket.svg')});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 768px) {
    height: 30px;
    width: calc(125px / 2);
  }
`;

const CallToActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Navbar = styled.div`
  background-color: #fff;
  height: 100px;
  width: 100vw;
  position: fixed;
  z-index: 50;
  top: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0px 2rem;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
  overflow: hidden;
  font-family: 'Termina';
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;
