export default {
  primary: '#1A9E99',
  secondary: '#00D18E',
  palette2: '#FF001D',
  palette3: '#FFA438',
  palette4: '#FF7600',
  greyA100: '#EEEEEE',
  greyA200: '#DADADA',
  greyA400: '#757575',
  greyA500: '#4F4F4F',
  greyA700: '#333333',
};
