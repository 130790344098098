import firebase from 'firebase';
import {Event} from 'react-big-calendar';

import {repairers} from '../firebase/firestoreCollections';
import {getRepairer} from '../account/accountActions';

type RepairerCalendar = {
  repairerUid: string;
  events: Event[];
};

export const saveCalendar = (calendar: RepairerCalendar) => {
  return firebase
    .firestore()
    .collection(repairers)
    .doc(calendar.repairerUid)
    .update({calendar: calendar.events});
};

export const addAnnualClosures = async (calendar: RepairerCalendar) => {
  const oldCalendar = await getCalendar(calendar.repairerUid);
  return firebase
    .firestore()
    .collection(repairers)
    .doc(calendar.repairerUid)
    .update({
      calendar: oldCalendar
        .filter((e) => !e.resource.isAnnualClosure)
        .concat(calendar.events),
    });
};

export const getCalendar = async (repairerUid: string): Promise<Event[]> => {
  const repairer = await getRepairer(repairerUid);

  if (repairer?.calendar) {
    return repairer.calendar.map((event) => ({
      ...event,
      start: event.start.toDate(),
      end: event.end.toDate(),
    }));
  }
  return [];
};
