import React from 'react';
import styled from 'styled-components';
import {Button} from '@material-ui/core';

import genders from './genders';
import colors from '../theme/colors';

export default ({selectedGender, onSelectGender}) => {
  return (
    <GenderButtons>
      <GenderButton
        style={{
          backgroundColor:
            selectedGender === genders.woman ? colors.primary : colors.greyA200,
          fontWeight: selectedGender === genders.woman ? 'bold' : 'normal',
        }}
        onClick={() => onSelectGender(genders.woman)}>
        femme
      </GenderButton>

      <GenderButton
        style={{
          backgroundColor:
            selectedGender === genders.man ? colors.primary : colors.greyA200,
          fontWeight: selectedGender === genders.man ? 'bold' : 'normal',
        }}
        onClick={() => onSelectGender(genders.man)}>
        homme
      </GenderButton>

      <GenderButton
        style={{
          backgroundColor:
            selectedGender === genders.child ? colors.primary : colors.greyA200,
          fontWeight: selectedGender === genders.child ? 'bold' : 'normal',
        }}
        onClick={() => onSelectGender(genders.child)}>
        enfant
      </GenderButton>
    </GenderButtons>
  );
};

const GenderButtons = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GenderButton = styled(Button)`
  width: 200px;
  margin: 20px;
`;
