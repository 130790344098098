import firebase from 'firebase';
import {urgenciesWebsocket} from '../firebase/firestoreCollections';

export const acceptDemand = (demandId: string, repairerUid: string) => {
  return firebase
    .firestore()
    .collection(urgenciesWebsocket)
    .doc(demandId)
    .update({
      acceptedByRepairerUids: firebase.firestore.FieldValue.arrayUnion(
        repairerUid,
      ),
    });
};
