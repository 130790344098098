import React, {useState} from 'react';
import _ from 'lodash';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import styled from 'styled-components';
import colors from '../theme/colors';
import translations from './ReparationPricesTranslation';

export default ({
  onCancel,
  reparationsLine,
  category,
  onSelectReparations,
  selectedLines = [],
  isEstimateSubmitted
}) => {
  const [nonListedLineText, setNonListedLineText] = useState<string>('');
  const [nonListedLinePrice, setNonListedLinePrice] = useState<number>(0);

  const uniqueLines = _.uniqBy(
    [...selectedLines, ...reparationsLine],
    'name',
  ).filter(l => l.category === category);

  const [localReparationsLine, setReparationsLine] = useState(
    uniqueLines.sort((a, b) => a.name.localeCompare(b.name)),
  );

  const total = localReparationsLine
    .filter(l => l.isSelected)
    .reduce(
      (acc, cur) => acc + cur.price,
      nonListedLineText ? nonListedLinePrice : 0,
    );

  const onPressEnter = e => {
    if (e.keyCode === 13) {
      return localSendEstimate();
    }
  };

  const localSendEstimate = async () => {
    const selectedLines = localReparationsLine.filter(l => l.isSelected);
    const estimateLines = nonListedLineText
      ? selectedLines.concat([
          {name: nonListedLineText, price: nonListedLinePrice},
        ])
      : selectedLines;

    await onSelectReparations(estimateLines, total);
    setNonListedLineText('');
    setNonListedLinePrice(0);
  };

  const onPriceChange = async (newInput, line) => {
    const newPrice = Number(newInput.target.value);
    const lineWithNewPrice = {...line, price: newPrice};

    setReparationsLine(
      localReparationsLine
        .filter(l => l.name !== line.name)
        .concat([lineWithNewPrice])
        .sort((a, b) => a.name.localeCompare(b.name)),
    );
  };

  const selectLine = line => {
    const newSelection = !line.isSelected;

    const changedSelection = {...line, isSelected: newSelection};
    setReparationsLine(
      localReparationsLine
        .filter(l => l.name !== line.name)
        .concat([changedSelection])
        .sort((a, b) => a.name.localeCompare(b.name)),
    );
  };

  return (
    <Dialog
      maxWidth={false}
      open={true}
      onClose={onCancel}
      onKeyDown={onPressEnter}
      disableBackdropClick
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Devis</DialogTitle>

      <DialogContent style={{alignItems: 'center'}}>
        {localReparationsLine.map(line => {
          return (
            <PriceLine>
              <Checkbox
                checked={line.isSelected}
                onChange={() => selectLine(line)}
                style={{paddingBottom: 0}}
              />
              <Label>{translations[line.name] || line.name}</Label>
              <GrayLine />
              <TextField
                onChange={e => onPriceChange(e, line)}
                type={'number'}
                defaultValue={line.price}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                style={{width: 50}}
              />
            </PriceLine>
          );
        })}

        <InputContainer>
          {localReparationsLine.length ? 'Autre' : <></>}
          <TextareaAutosize
            style={{minWidth: 600, marginLeft: 10}}
            aria-label="Estimate"
            rowsMin={3}
            placeholder="Tâches non listées"
            onChange={text => setNonListedLineText(text.target.value)}
            autoFocus
            id="estimate"
          />
          <GrayLine />
          <TextField
            value={nonListedLinePrice}
            onChange={p => setNonListedLinePrice(Number(p.target.value))}
            type="number"
            id="price"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            style={{width: 60}}
          />
        </InputContainer>

        <Total>
          Total : <b style={{marginLeft: 3}}>{total} €</b>
        </Total>
      </DialogContent>
      <DialogActions style={{justifyContent: 'end'}}>
        <Button onClick={onCancel} variant="contained">
          Annuler
        </Button>
        <Button
          style={{color: 'white', marginLeft: 10}}
          onClick={localSendEstimate}
          variant="contained"
          color="primary"
          disabled={isEstimateSubmitted}>
          {!isEstimateSubmitted ? (
            <span>Valider</span>
          ) : (
            <CircularProgress/>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InputContainer = styled.div`
  display: flex;
  align-items: end;
  margin-top: 20px;
`;

const PriceLine = styled.div`
  display: flex;
  align-items: end;
`;

const Label = styled.span`
  color: ${colors.greyA500};
  align-self: flex-end;
  font-size: 16px;
  line-height: 14px;
`;

const GrayLine = styled.span`
  color: ${colors.greyA500};
  flex: 1;
  display: flex;
  border-bottom: 1px solid ${colors.greyA200};
  margin-left: 10px;
  margin-right: 10px;
`;

const Total = styled.div`
  flex: 1;
  display: flex;
  text-align: center;
  font-size: 18px;
  justify-content: center;
  margin-top: 5px;
`;
