import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import {Controller} from 'react-hook-form';

const RegisterFormRadioField = ({
  classes,
  label,
  name,
  options,
  control,
  iconComponent,
}: {
  classes: any;
  label: string;
  name: string;
  control: any;
  options: {[key: string]: string};
  iconComponent: JSX.Element;
}): JSX.Element => {
  return (
    <div className={classes.cardRow} style={{marginTop: '1rem'}}>
      {iconComponent}
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          rules={{required: true}}
          control={control}
          name={name}
          as={
            <RadioGroup
              aria-label={label}
              name={name}
              value={options[Object.keys(options)[0]]}>
              {Object.keys(options).map((key: string) => (
                <FormControlLabel
                  key={key}
                  control={<Radio />}
                  label={key}
                  value={options[key]}
                />
              ))}
            </RadioGroup>
          }
        />
      </FormControl>
    </div>
  );
};

export default RegisterFormRadioField;
