import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../util/TabPanel';
import PublishAdvertisment from './PublishAdvertisment';
import MyAdvertisements from './MyAdvertisements';

export default () => {
  const [tab, setTab] = React.useState(0);

  return (
    <>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        centered
        onChange={(e, t) => setTab(t)}
        aria-label="tabs">
        <Tab label="Vente" />
        <Tab label="Mes annonces" />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <PublishAdvertisment />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <MyAdvertisements />
      </TabPanel>
    </>
  );
};
