import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {useToasts} from 'react-toast-notifications';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

import I18n from '../translations/i18n';
import Button from '../components/Button';
import GrayButton from '../components/GrayButton';
import {updateReparation} from '../reparations/reparationsActions';
import reparationStatutes from '../reparations/reparationStatutes';
import {sendControlDoneNotification} from '../notifications/sendNotification';
import Stars from './Stars';

export default ({control, isModalOpened, openModal}) => {
  const {addToast} = useToasts();
  const classes = useStyles();

  const [controls, setControls] = useState<any>([]);

  useEffect(() => {
    setControls(control?.control);
  }, [control]);

  const saveEstimate = async () => {
    try {
      await Promise.all([
        updateReparation(control.uid, {
          control: controls,
          status: reparationStatutes.waitingForRemoval,
        }),
        sendControlDoneNotification(control.userUid),
      ]);

      addToast(I18n.t('saved'), {appearance: 'success'});
      return openModal(false);
    } catch (e) {
      addToast(I18n.t('errorOccurred'), {
        appearance: 'error',
      });
    }
  };

  const changeRating = (newRating, reparationIndex) => {
    setControls(
      controls.map((r, i) => {
        if (i === reparationIndex) return {...r, rating: newRating};
        return r;
      }),
    );
  };

  const ControlsTable = ({controls, changeRating}) => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Contrôle</StyledTableCell>
            <StyledTableCell align="center">Note</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {controls.map((control, index) => (
            <StyledTableRow key={control.uid}>
              <StyledTableCell align="center" component="th" scope="row">
                {control.reparationText}
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stars
                  rating={control.rating}
                  changeRating={newRating => changeRating(newRating, index)}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={isModalOpened}
        onClose={() => openModal(false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Mettre à jour les notes sur les points de contrôle concernés par les
          interventions
        </DialogTitle>

        <DialogContent>
          <ControlsTable controls={controls} changeRating={changeRating} />
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-evenly',
          }}>
          <GrayButton onClick={() => openModal(false)}>Annuler</GrayButton>
          <Button onClick={saveEstimate} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const StyledTableCell = withStyles(({palette}: Theme) =>
  createStyles({
    head: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {},
});
