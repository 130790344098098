import React, {FunctionComponent, useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {Controller, useForm} from 'react-hook-form';
import {
  Button,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import {
  Email,
  Face,
  Lock,
  Storefront,
  Home,
  Phone,
  Smartphone,
  CalendarToday,
  Notifications,
} from '@material-ui/icons';
import firebase from 'firebase';
import {usePlacesWidget} from 'react-google-autocomplete';

import {createUser} from '../users/usersActions';
import I18n from '../translations/i18n';
import isDev from './isDev';
import useStyles, {StylesClassesType} from './useStyles';
import routes from '../navigation/routes';
import {createRepairer} from '../repairers/repairerActions';
import {defaultDurations} from '../account/ReparationDurations';
import {defaultReparationPrices} from '../reparations/defaultReparationPrices';
import {UserFormDataType} from './RegisterForm.types';
import User from '../users/User.interface';
import RegisterFormField from './sub-components/RegisterFormField';
import RegisterFormRadioField from './sub-components/RegisterFormRadioField';

const RegisterForm: FunctionComponent = () => {
  const classes: StylesClassesType = useStyles();
  const history = useHistory();
  const {addToast} = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();

  /**
   * Init "Place" autocompletion
   */
  const {ref: materialRef} = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    onPlaceSelected: place => {
      setAddress(place.formatted_address);
      setLatitude(place.geometry.location.lat);
      setLongitude(place.geometry.location.lng);
    },
    options: {
      types: [],
    },
  });

  /**
   * Init form default values
   */
  const defaultValues: UserFormDataType = isDev
    ? {
        firstName: 'simon',
        lastName: 'garnier',
        repairerName: 'test réparateur',
        email: 'biket@test.test',
        password: 'biket@test.test',
        phoneNumber: '0642101143',
        address: '',
        isReparerWithFlow: 'true',
        emailNotificationEnabled: 'true',
      }
    : {
        firstName: '',
        lastName: '',
        repairerName: '',
        email: '',
        password: '',
        phoneNumber: '',
        address: '',
        isReparerWithFlow: 'true',
        emailNotificationEnabled: 'true',
      };

  /**
   * Init controlled form handling
   */
  const {handleSubmit, register, control} = useForm<UserFormDataType>({
    defaultValues,
  });

  const onSubmit = async (data: UserFormDataType): Promise<void> => {
    setIsLoading(true);

    if (
      !data.email ||
      !data.phoneNumber ||
      !data.password ||
      !data.firstName ||
      !data.lastName ||
      !data.repairerName ||
      !address ||
      data.isReparerWithFlow === undefined ||
      data.emailNotificationEnabled === undefined
    ) {
      addToast('Veuillez remplir tous les champs', {appearance: 'error'});
      return;
    }

    try {
      const info = await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .catch(error => {
          switch (error.code) {
            case 'auth/email-already-in-use':
              addToast(I18n.t('alreadyUsedEmail'), {
                appearance: 'error',
              });
              break;

            case 'auth/invalid-email':
              addToast(I18n.t('invalidEmail'), {appearance: 'error'});

              break;

            case 'auth/weak-password':
              addToast(I18n.t('tooShortPassword'), {appearance: 'error'});

              break;

            default:
              addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
          }
        });

      history.push(routes.home);

      if (info) {
        const user: User = {
          uid: info.user!.uid,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
        };

        await Promise.all([
          // auth().currentUser.updateProfile({
          //   displayName: firstName,
          // }),
          createUser(user),
          createRepairer({
            uid: user.uid,
            repairerUid: user.uid,
            email: user.email,
            name: data.repairerName,
            phoneNumber: data.phoneNumber,
            address,
            coordinates: new firebase.firestore.GeoPoint(latitude, longitude),
            reparationDurations: defaultDurations,
            reparationPrices: defaultReparationPrices,
            isFlowActive: data.isReparerWithFlow === 'true',
            emailNotificationEnabled: data.emailNotificationEnabled === 'true',
          }),
        ]);
      }
    } catch (e) {
      console.log('e', e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Card className={classes.registerContainer}>
        <Card className={classes.header} elevation={1} square>
          <p className={classes.headerTitle}>Back-office Biket</p>
        </Card>

        <CardContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.registerCardContent}>
            {/* Repairer (worker) first name */}
            <RegisterFormField
              classes={classes}
              iconComponent={<Face className={classes.icon} />}
              name="firstName"
              autoCapitalize="words"
              inputRef={register({required: I18n.t('firstNameIsRequired')})}
              placeholder="Aron"
              label={I18n.t('firstName')}
              autoFocus
            />

            {/* Repairer (worker) last name */}
            <RegisterFormField
              classes={classes}
              iconComponent={<Face className={classes.icon} />}
              name="lastName"
              inputRef={register({required: I18n.t('lastNameIsRequired')})}
              placeholder="Smith"
              label={I18n.t('lastName')}
              autoCapitalize="words"
            />

            {/* Repairer (shop) name */}
            <RegisterFormField
              classes={classes}
              iconComponent={<Storefront className={classes.icon} />}
              name="repairerName"
              inputRef={register({required: 'Nom du magasin requis'})}
              placeholder=""
              label={'Nom du magasin'}
            />

            {/* Repairer address */}
            {/* Warning Managed by usePlacesWidget() and store in state */}
            <RegisterFormField
              classes={classes}
              iconComponent={<Home className={classes.icon} />}
              inputRef={materialRef}
              placeholder=""
              label={'Adresse du magasin'}
            />

            {/* Repairer email */}
            <RegisterFormField
              classes={classes}
              iconComponent={<Email className={classes.icon} />}
              name="email"
              type="email"
              autoCapitalize="none"
              inputRef={register({required: I18n.t('emailIsRequired')})}
              label="Email"
              placeholder="aron@smith.com"
            />

            {/* Repairer phone number */}
            <RegisterFormField
              classes={classes}
              iconComponent={<Phone className={classes.icon} />}
              type="tel"
              name="phoneNumber"
              inputRef={register({required: I18n.t('phoneNumberIsRequired')})}
              label={I18n.t('phoneNumber')}
              placeholder="0601020304"
              inputProps={{pattern: '[0-9]{10}'}}
            />

            {/* User password */}
            <RegisterFormField
              classes={classes}
              iconComponent={<Lock className={classes.icon} />}
              type="password"
              name="password"
              autoCapitalize="none"
              inputRef={register({required: I18n.t('passwordIsRequired')})}
              label={I18n.t('password')}
              placeholder="**********"
            />

            <RegisterFormRadioField
              classes={classes}
              name="isReparerWithFlow"
              label={I18n.t('isReparerWithFlow')}
              options={{
                'Prise de rendez-vous': 'true',
                'Annuaire seulement': 'false',
              }}
              control={control}
              iconComponent={<CalendarToday className={classes.icon} />}
            />
            <RegisterFormRadioField
              classes={classes}
              name="emailNotificationEnabled"
              label={I18n.t('emailNotificationEnabled')}
              options={{
                Oui: 'true',
                Non: 'false',
              }}
              control={control}
              iconComponent={<Notifications className={classes.icon} />}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                width: '100%',
                marginBottom: -100,
              }}>
              <Button
                onClick={() => history.push(routes.login)}
                className={classes.registerButton}>
                {I18n.t('back')}
              </Button>

              <Button type="submit" className={classes.button}>
                {I18n.t('createAnAccount')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default RegisterForm;
