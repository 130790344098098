import React, {useState} from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {Button, IconButton, Snackbar} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

import colors from '../theme/colors';
import DayTimeRangeSelector from './DayTimeRangeSelector';
import {updateRepairer} from './accountActions';
import AnnualClosures from './AnnualClosures';
import ReparationPrices from './ReparationPrices';
import AppointmentSettings from './AppointmentSettings';
import {useUser, useUserDispatch} from '../auth/userContext';
import TabPanel from '../util/TabPanel';
import problems from '../reparations/problems';
import GeneralInfo from './GeneralInfo';

export default () => {
  const {uid, calendarSlotDuration, reparationDurations} = useUser();
  const userDispatch = useUserDispatch();

  const [tab, setTab] = React.useState(0);
  const [, setSlotDuration] = React.useState<number>(30);
  const [toastMessage, setToastMessage] = useState('');

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleSlotDurationChange = async (
    event: any,
    newValue: number | number[],
  ) => {
    try {
      await updateRepairer(uid, {calendarSlotDuration: newValue});
      setSlotDuration(newValue as number);
      setToastMessage('Enregistré');
      userDispatch({calendarSlotDuration: newValue});
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleReparationDurationChange = async (
    event: any,
    newValue: number | number[],
    reparationName: string,
  ) => {
    try {
      const newReparationDurations = {
        ...reparationDurations,
        [reparationName]: newValue as number,
      };
      await updateRepairer(uid, {reparationDurations: newReparationDurations});
      setSlotDuration(newValue as number);
      setToastMessage('Enregistré');
      userDispatch({reparationDurations: newReparationDurations});
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastMessage('');
  };

  const marks = [
    {
      value: 15,
      label: '15 minutes',
    },
    {
      value: 30,
      label: '30 minutes',
    },
    {
      value: 60,
      label: '1 heure',
    },
    {
      value: 120,
      label: '2 heures',
    },
  ];

  function valuetext(value: number) {
    return `${value} minutes`;
  }

  return (
    <div>
      <h1 style={{color: colors.greyA500, textAlign: 'center'}}>
        Configuration du compte
      </h1>

      <Paper square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          centered
          onChange={handleTabChange}
          aria-label="tabs">
          <Tab label="Horaires d'ouverture" />
          <Tab label="Fermetures annuelles" />
          <Tab label="Configuration de l'agenda" />
          <Tab label="Durée des réparations" />
          <Tab label="Tarifs des réparations" />
          <Tab label="Prise de rendez-vous" />
          <Tab label="Informations générales" />
        </Tabs>
      </Paper>

      <TabPanel value={tab} index={0}>
        <ul>
          <li>Lundi</li>
          <DayTimeRangeSelector dayNumber={1} />

          <li>Mardi</li>
          <DayTimeRangeSelector dayNumber={2} />

          <li>Mercredi</li>
          <DayTimeRangeSelector dayNumber={3} />

          <li>Jeudi</li>
          <DayTimeRangeSelector dayNumber={4} />

          <li>Vendredi</li>
          <DayTimeRangeSelector dayNumber={5} />

          <li>Samedi</li>
          <DayTimeRangeSelector dayNumber={6} />

          <li>Dimanche</li>
          <DayTimeRangeSelector dayNumber={0} />
        </ul>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <AnnualClosures />
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{width: '90%'}}>
            <Typography
              id="discrete-slider-custom"
              component="span"
              gutterBottom>
              Taille des créneaux :
            </Typography>
            <Slider
              onChangeCommitted={handleSlotDurationChange}
              defaultValue={calendarSlotDuration ? calendarSlotDuration : 30}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-custom"
              step={null}
              valueLabelDisplay="auto"
              marks={marks}
              min={15}
              max={120}
            />
          </div>
        </div>
      </TabPanel>

      <TabPanel value={tab} index={3}>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            {Object.keys(problems).map(reparationName => (
              <div style={{width: '90%', marginTop: 50}} key={reparationName}>
                <Typography
                  id="discrete-slider-custom"
                  component="span"
                  gutterBottom>
                  {problems[reparationName]}
                </Typography>
                <Slider
                  onChangeCommitted={(event, newValue) =>
                    handleReparationDurationChange(
                      event,
                      newValue,
                      reparationName,
                    )
                  }
                  defaultValue={
                    reparationDurations && reparationDurations![reparationName]
                      ? reparationDurations![reparationName]
                      : 15
                  }
                  step={15}
                  marks={marks}
                  min={15}
                  max={120}
                />
              </div>
            ))}
          </div>
        </div>
      </TabPanel>

      <CenteredTab value={tab} index={4}>
        <ReparationPrices />
      </CenteredTab>

      <CenteredTab value={tab} index={5}>
        <AppointmentSettings />
      </CenteredTab>

      <CenteredTab value={tab} index={6}>
        <GeneralInfo />
      </CenteredTab>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message={toastMessage}
        action={
          <>
            <Button color="primary" size="small" onClick={handleClose}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

const CenteredTab = styled(TabPanel)`
  display: flex;
  justify-content: center;
`;
