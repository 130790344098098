export const users = 'users';
export const usersBikes = 'usersBikes';

export const reparations = 'reparations';
export const repairers = 'repairers';

export const urgenciesWebsocket = 'urgenciesWebsocket';
export const manualAppointmentsWebsocket = 'manualAppointmentsWebsocket';

export const salesCards = 'salesCards';
