import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import {useToasts} from 'react-toast-notifications';
import {useHistory} from 'react-router-dom';

import colors from '../theme/colors';
import {deactivateSaleCard, getMyActiveSaleCards} from './marketPlaceActions';
import {useUser} from '../auth/userContext';
import FullPageSpinner from '../util/FullPageSpinner';
import I18n from '../translations/i18n';
import routes from '../navigation/routes';

export default () => {
  const {addToast} = useToasts();
  const history = useHistory();

  const me = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [mySalesCards, setMySalesCards] = useState<any>([]);

  const deleteSaleCard = async (saleCardUid: string) => {
    await deactivateSaleCard(saleCardUid);
    addToast(I18n.t('deleted'), {appearance: 'success'});
  };

  useEffect(() => {
    async function initData() {
      const salesCards = await getMyActiveSaleCards(me.uid);
      setMySalesCards(
        salesCards.sort(
          (a, b) =>
            b.creationDate.toDate().getTime() -
            a.creationDate.toDate().getTime(),
        ),
      );
      setIsLoading(false);
    }
    initData();
  }, [me.uid]);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <MainContainer>
      <Title>Mes annonces en ligne</Title>

      <SList dense>
        {mySalesCards.map(saleCard => {
          return (
            <ListItem
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 12,
              }}
              key={saleCard.uid}>
              <ListItemIcon
                style={{cursor: 'pointer'}}
                onClick={() =>
                  history.push(
                    routes.updateAdvertismentWithoutParam + saleCard.uid,
                  )
                }>
                <ListItemAvatar>
                  <Avatar
                    style={{width: 80, height: 80, marginRight: 20}}
                    src={saleCard.images.filter(i => i)[0]}
                  />
                </ListItemAvatar>
              </ListItemIcon>

              <ListItemText
                style={{cursor: 'pointer'}}
                onClick={() =>
                  history.push(
                    routes.updateAdvertismentWithoutParam + saleCard.uid,
                  )
                }
                primary={saleCard.title}
                secondary={
                  saleCard.price +
                  ' €' +
                  ' créée le ' +
                  moment(saleCard.creationDate.toDate()).format('LLL')
                }
              />

              <ListItemSecondaryAction
                onClick={() => deleteSaleCard(saleCard.uid)}>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </SList>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  color: ${colors.greyA500};
`;

const SList = styled(List)`
  max-width: 500px;
  margin-top: 30px;
  margin-left: 50px;
`;
