import React, {FunctionComponent, useEffect, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, Snackbar, TextField} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

import colors from '../theme/colors';
import reparationTypesEnum from '../entriesAndExits/reparationTypesEnum';
import ControlEstimateModal from '../estimates/ControlEstimateModal';
import {filterReparationForTabsFunctionTemplate} from "../lib/filterReparationForTabsFunctionTemplate";
import Reparation from "../reparations/Reparation.interface";

type ControlsToCompleteProps = {
  pendingControls: Reparation[]
}

const ControlsToComplete: FunctionComponent<ControlsToCompleteProps> = ({pendingControls}) => {
  const [isControlEstimateOpen, setIsControlEstimateOpen] = useState(false);
  const [controlEstimate, setControlEstimate] = useState();

  const [filteredPendingControls, setFilteredPendingControls] = useState<any>(
    pendingControls,
  );
  const [toastMessage, setToastMessage] = useState<string>();

  // Refresh filtered reparations if reparations from props as change
  useEffect(() => setFilteredPendingControls(pendingControls), [pendingControls])

  const controlTypeText = entry => {
    if (entry.controlType === reparationTypesEnum.urgency) return 'Urgence';
    if (entry.controlType === reparationTypesEnum.appointment)
      return 'Rendez-vous';
    if (entry.controlType === reparationTypesEnum.immediate)
      return 'Réparation sous 48 heures';
  };

  const markAsControlled = async entry => {
    try {
      setControlEstimate(entry);
      setIsControlEstimateOpen(true);
    } catch (e) {
      console.log('e', e);
      setToastMessage("Une erreur s'est produite, veuillez réessayer");
    }
  };

  const filterByUserName = filterReparationForTabsFunctionTemplate(pendingControls, setFilteredPendingControls)

  return (
    <>
      <SearchBar>
        <SSearchIcon />
        <TextField
          label="Filtrer par nom de client"
          onChange={filterByUserName}
          name="filter"
        />
      </SearchBar>

      <ControlEstimateModal
        isModalOpened={isControlEstimateOpen}
        openModal={setIsControlEstimateOpen}
        control={controlEstimate}
      />

      {filteredPendingControls.map((entry, i) => (
        <List dense key={entry.uid}>
          <ListItem style={i % 2 ? {backgroundColor: colors.greyA200} : {}}>
            <ListItemText
              primary={entry.title}
              secondary={moment(
                entry.start
                  ? entry.start.toDate()
                  : entry.creationDate.toDate(),
              ).format('LLL')}
            />
            <ControlType>{controlTypeText(entry)}</ControlType>
            <Button
              variant="contained"
              color="primary"
              onClick={() => markAsControlled(entry)}>
              <strong>Contrôle terminé</strong>
            </Button>
          </ListItem>
        </List>
      ))}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={() => setToastMessage('')}
        message={toastMessage}
        action={
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => setToastMessage('')}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastMessage('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default ControlsToComplete

const ControlType = styled.span`
  color: ${colors.palette3};
  font-weight: bold;
  margin-right: 20px;
`;

const SearchBar = styled.form`
  align-items: end;
  justify-content: center;
  display: flex;
`;

const SSearchIcon = styled(SearchIcon)`
  margin-right: 12px;
  color: ${colors.palette3};
  font-size: 30px;
`;
