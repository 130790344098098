import cloudFunctionsBaseUrl from "./cloudFunctionsBaseUrl";

export const SEND_NOTIFICATION = (userUid, title, message) => {
  return (
    cloudFunctionsBaseUrl +
    'sendNotification?userUid=' +
    userUid +
    '&title=' +
    title +
    '&message=' +
    message
  );
};
