import React from 'react';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import styled from 'styled-components';

import {UserProvider} from '../auth/userContext';
import Menu, {MENU_WIDTH} from './Menu';
import AccountSettings from '../account/AccountSettings';
import routes from './routes';
import {OpenHoursProvider} from '../account/openHoursContext';
import CalendarTabs from '../calendar/CalendarTabs';
import UrgenciesModal from '../urgencies/UrgenciesModal';
import {UrgenciesProvider} from '../urgencies/urgenciesContext';
import MarketPlaceTabs from '../marketPlace/MarketPlaceTabs';
import PublishAdvertisment from '../marketPlace/PublishAdvertisment';
import MobileAppConfidentiality from './MobileAppConfidentiality';
import TermsOfService from './TermsOfService';

export default () => {
  return (
    <UserProvider>
      <Router>
        <Menu />

        <PageContainer>
          <UrgenciesProvider>
            <OpenHoursProvider>
              <Switch>
                <Route path={routes.home} exact>
                  <CalendarTabs />
                </Route>

                <Route path={routes.accountSettings} exact>
                  <AccountSettings />
                </Route>

                <Route path={routes.marketPlace} exact>
                  <MarketPlaceTabs />
                </Route>

                <Route path={routes.updateAdvertisment} exact>
                  <PublishAdvertisment />
                </Route>
              </Switch>

              <UrgenciesModal />
            </OpenHoursProvider>
          </UrgenciesProvider>
        </PageContainer>

        <Route path={routes.confidentiality} exact>
          <MobileAppConfidentiality />
        </Route>

        <Route path={routes.termsOfService} exact>
          <TermsOfService />
        </Route>
      </Router>
    </UserProvider>
  );
};

const PageContainer = styled.div`
  margin-left: ${MENU_WIDTH};
`;
