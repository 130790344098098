import React, {FunctionComponent} from "react";
import {TextField, TextFieldProps} from "@material-ui/core";
import {StylesClassesType} from "../useStyles";

export type RegisterFormFieldProps = TextFieldProps & {
  classes: StylesClassesType
  iconComponent: JSX.Element
}

/**
 * Field for Register form.
 * Warning : by default TextField is "required"
 */
const RegisterFormField: FunctionComponent<RegisterFormFieldProps> =
  ({classes, iconComponent, children, ...textFieldProps}) => (
    <div className={classes.cardRow}>
      {iconComponent}
      <TextField
        fullWidth
        autoFocus
        required
        {...textFieldProps}
      />
    </div>
  );

export default RegisterFormField