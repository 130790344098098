import React from 'react';
import styled from 'styled-components';

import FilledStar from './stars/FilledStar';
import colors from '../theme/colors';
import NonFilledStar from './stars/NonFilledStar';

const starSize = 24;

export default ({rating, changeRating}) => {
  return (
    <StarsDiv>
      {[1, 2, 3, 4, 5].map(r => {
        return (
          <div
            onClick={() => {
              changeRating(r);
            }}
            key={r}>
            {r <= rating ? (
              <FilledStar
                style={{
                  height: starSize,
                  width: starSize,
                  color: colors.secondary,
                  margin: 12,
                }}
              />
            ) : (
              <NonFilledStar
                style={{
                  height: starSize,
                  width: starSize,
                  margin: 12,
                }}
              />
            )}
          </div>
        );
      })}
    </StarsDiv>
  );
};

const StarsDiv = styled.div`
  display: flex;
  justify-content: center;
`;
