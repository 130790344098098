export const defaultReparationPrices = {
  tires: {
    changeTube: 18,
    changeSpecialBikeTube: 30,
    changeTire: 38,
    changeAntiPunctureTire: 55,
    changeSpecialBikeTire: 45,
    changeSpecialBikeAntiPunctureTire: 65,
    tireUnveiling: 18,
    changeHybridBikeFrontTire: 69,
    changeHybridBikeRearTire: 72,
    changeRoadBikeFrontTire: 77,
    changeRoadBikeRearTire: 77,
  },
  brakes: {
    frontAndRear: 22,
    changeBikeSkates: 20,
    changeBikeSkatesForHydraulicBrakes: 30,
    changeBrakeCable: 24,
    changeBrakeCableForDiscBrakes: 24,
    changeBrakeCaliperForSpecial: 35,
    changeBrakeCaliperForRoad: 44,
    changeBrakeLeverSpecial: 25,
    discBrakeAdjustment: 19,
    changeBrakePad: 32,
    bleedingHydraulicDiscBrake: 45,
    bleedingHydraulicDiscBrakeOnRim: 55,
  },
  gears: {
    reglageDesVitesses: 25,
    changementChaine1678: 33,
    changementChaine9: 44,
    changementChaine10: 45,
    changementRoueLibre: 40,
    changementCassette: 45,
    montagePedalier: 30,
    changementBoitier: 65,
    changementDerailleur: 49,
    changementDerailleurExterne567: 44,
    changementDerailleurExterne9: 77,
    changementCablePlusGaine: 24,
    changementManetteDerailleur: 39,
    changementManivelleGauche: 28,
    installationPairePedales: 35,
    reglageJeuMoyeu: 21,
  },
  electrique: {
    revisionComplete: 65,
    diagnostique: 45,
    miseAJour: 25,
    installationCartographie: 25,
    changementCapteur: 25,
    changementMoteur: 65,
    changementControleur: 35,
    miseEnServiceElectrique: 75,
    changementConnectique: 25,
    changementBatterie: 25,
    priseEnChargeSAV: 35,
    etalonnageCapteur: 25,
  },
  overhaul: {
    pack: 28,
    miseEnService: 55,
    nettoyage: 30,
    complete: 39,
  },
  other: {
    changementSelle: 40,
    installationPetitAccessoire: 10,
    installationGrosAccessoire: 30,
    installationGardeBoueClips: 40,
    installationGardeBoueTringles: 60,
    remplacementGuidoline: 44,
    remplacementTigeSelle: 40,
    decoupeCadenas: 38,
  },
};
