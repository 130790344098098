import firebase from 'firebase';
import {initializeApp} from 'geofirestore';

import {salesCards} from '../firebase/firestoreCollections';

export const createSaleCard = card => {
  const geoFirestore = initializeApp(firebase.firestore());
  const collection = geoFirestore.collection(salesCards);

  return collection.doc(card.userBikeUid).set({
    uid: card.userBikeUid,
    isSoldByRepairer: true,
    creationDate: new Date(),
    active: true,
    likes: [],
    ...card,
  });
};

export const deactivateSaleCard = (cardUid: string) => {
  firebase
    .firestore()
    .collection(salesCards)
    .doc(cardUid)
    .update({active: false});
};

export const getMyActiveSaleCards = (userUid: string) => {
  return firebase
    .firestore()
    .collection(salesCards)
    .where('sellerUid', '==', userUid)
    .where('active', '==', true)
    .get()
    .then(salesCards => salesCards.docs.map(s => s.data()));
};

export const getSaleCard = (cardUid: string) => {
  return firebase
    .firestore()
    .collection(salesCards)
    .doc(cardUid)
    .get()
    .then(salesCard => salesCard.data());
};
