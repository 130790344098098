import React from 'react';
import {Switch} from '@material-ui/core';
import _ from 'lodash';

import TimeRangeSelector from './TimeRangeSelector';
import {dayOff, emptyOpenHours, isCloseDay} from './OpenHours';
import {useOpenHours, useOpenHoursDispatch} from './openHoursContext';
import {updateRepairer} from './accountActions';
import {useUser, useUserDispatch} from '../auth/userContext';

const DayTimeRangeSelector = ({dayNumber}) => {
  const {uid} = useUser();
  const userDispatch = useUserDispatch();
  const openHours = useOpenHours();
  const dispatch = useOpenHoursDispatch();

  const handleSwitchChange = async () => {
    let openHoursCopy = _.cloneDeep(openHours);

    if (isCloseDay(dayNumber, openHours)) {
      openHoursCopy[dayNumber] = [[8, 12], [14, 18]];
    } else {
      openHoursCopy[dayNumber] = dayOff;
    }

    await updateRepairer(uid, {openHours: JSON.stringify(openHoursCopy)});
    dispatch(openHoursCopy);
    userDispatch({openHours: JSON.stringify(openHoursCopy)});
  };

  const DaySwitch = () => {
    return (
      <Switch
        checked={!isCloseDay(dayNumber, openHours)}
        onChange={handleSwitchChange}
        color="primary"
      />
    );
  };

  return (
    <div>
      <DaySwitch />
      {!isCloseDay(dayNumber, openHours) && (
        <div>
          Matin :
          <TimeRangeSelector
            dayNumber={dayNumber}
            initialStart={openHours[dayNumber][0][0]}
            initialEnd={openHours[dayNumber][0][1]}
            isMorning
          />
          Après-midi :
          <TimeRangeSelector
            dayNumber={dayNumber}
            initialStart={openHours[dayNumber][1][0]}
            initialEnd={openHours[dayNumber][1][1]}
          />
        </div>
      )}
    </div>
  );
};

export default DayTimeRangeSelector;
