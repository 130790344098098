import React, {useState} from 'react';
import {KeyboardTimePicker} from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import {Button, IconButton, Snackbar} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {useOpenHours, useOpenHoursDispatch} from './openHoursContext';
import {TimeRangeSelectorProps} from './OpenHours';
import {updateRepairer} from './accountActions';
import {useUser, useUserDispatch} from '../auth/userContext';

const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({
  dayNumber,
  isMorning,
  initialStart,
  initialEnd,
}) => {
  const {uid} = useUser();
  const userDispatch = useUserDispatch();
  const openHours = useOpenHours();
  const dispatch = useOpenHoursDispatch();

  const extractDateFromDBField = (dbField : string | null) => {
    const startHour = Number(dbField?.toString().split('.')[0] || 0);
    let startMinutes: any = dbField?.toString().split('.')[1] || 0;

    if (!startMinutes) {
      startMinutes = 0;
    } else if (Number(startMinutes) < 10 && !startMinutes.startsWith('0')) {
      startMinutes = Number(startMinutes) * 10;
    } else {
      startMinutes = Number(startMinutes);
    }

    return moment()
      .hour(dbField ? startHour : 0)
      .minutes(dbField ? startMinutes : 0);
  };

  const [startTime, setStartTime] = useState(
    extractDateFromDBField(initialStart),
  );
  const [endTime, setEndTime] = useState(extractDateFromDBField(initialEnd));
  const [toastMessage, setToastMessage] = useState('');

  // Same as front-end
  const dateToHour = (date): number => {
    const minutes = date.minutes() < 10 ? '0' + date.minutes() : date.minutes();
    return Number(date.hours() + '.' + minutes);
  };

  const setAndDispatchStartTime = date => {
    if (date === null) return; // Skip if input is empty

    const newStartTime = dateToHour(date);
    const newEndTime = dateToHour(endTime);
    if (newStartTime > newEndTime) {
      setToastMessage('Date de fin antérieure à celle du début');
      return;
    }

    setStartTime(date);

    dispatchNewHours(newStartTime, newEndTime);
  };

  const setAndDispatchEndTime = date => {
    if (date === null) return; // Skip if input is empty

    const newStartTime = dateToHour(startTime);
    const newEndTime = dateToHour(date);
    if (newStartTime > newEndTime) {
      setToastMessage('Date de fin antérieure à celle du début');
      return;
    }

    setEndTime(date);

    dispatchNewHours(newStartTime, newEndTime);
    setToastMessage('Enregistré');
  };

  const dispatchNewHours = async (newStartTime, newEndTime) => {
    let openHoursCopy = _.cloneDeep(openHours);
    let openHoursOfTheDay = openHoursCopy[dayNumber];

    if (isMorning) {
      openHoursCopy[dayNumber] = [
        [newStartTime, newEndTime],
        openHoursOfTheDay[1],
      ];
    } else {
      openHoursCopy[dayNumber] = [
        openHoursOfTheDay[0],
        [newStartTime, newEndTime],
      ];
    }
    try {
      await updateRepairer(uid, {openHours: JSON.stringify(openHoursCopy)});
      dispatch(openHoursCopy);
      userDispatch({openHours: JSON.stringify(openHoursCopy)});
      setToastMessage('Enregistré');
    } catch (e) {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastMessage('');
  };

  return (
    <div>
      <KeyboardTimePicker
        margin="normal"
        label="Début"
        value={startTime}
        onChange={setAndDispatchStartTime}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        ampm={false}
      />

      <KeyboardTimePicker
        margin="normal"
        label="Fin"
        value={endTime}
        onChange={setAndDispatchEndTime}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        ampm={false}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message={toastMessage}
        action={
          <>
            <Button color="primary" size="small" onClick={handleClose}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default TimeRangeSelector;
