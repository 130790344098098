import React, {useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Button} from '@material-ui/core';
import ImageSlides from 'react-imageslides';

import {Demand} from '../urgencies/urgenciesContext';
import colors from '../theme/colors';
import {
  acceptDemand,
  finallyRefuseDemand,
  refuseDemand,
} from './manualAppointmentsActions';
import {useUser} from '../auth/userContext';
import problems from '../reparations/problems';

const ManualAppointments = ({demands = []}) => {
  const {uid} = useUser();

  const [showSlides, setShowSlides] = useState<any>(false);

  const timerText = demand => {
    if (demand.acceptedByRepairerUids.includes(uid)) {
      return 'Disponibilité confirmée en attente de contre validation du client';
    } else {
      return (
        'à valider dans les ' +
        Math.round(
          moment
            .duration(
              moment(demand.creationDate.toDate())
                .add(30, 'minutes')
                .diff(moment()),
            )
            .asMinutes(),
        ) +
        ' minutes'
      );
    }
  };

  if (showSlides) {
    return (
      <ImageSlides
        index={0}
        tapClose={true}
        onClose={() => setShowSlides(false)}
        images={showSlides.photos.concat(showSlides.bikeImage).filter(p => p)}
        isOpen={showSlides}
        showPageButton
        addon={() => {
          return (
            <a
              href="/#"
              style={{
                position: 'absolute',
                color: 'white',
                top: 10,
                right: 30,
                fontSize: 40,
                zIndex: 999,
                fontWeight: 'bold',
                height: 50,
                width: 150,
              }}
              onClick={() => setShowSlides(false)}>
              Fermer
            </a>
          );
        }}
      />
    );
  }

  return (
    <MainContainer>
      <List dense>
        {demands.map((d, i) => {
          const demand = d as Demand;
          const date = demand.appointmentDate
            ? moment(demand.appointmentDate.toDate()).format('LLL')
            : '';

          return (
            <ListItem
              style={i % 2 ? {backgroundColor: colors.greyA200} : {}}
              key={demand.demandId}>
              <ListItemText
                primary={
                  (problems[demand.problem] || 'Réparation inconnue') +
                  ' le ' +
                  date
                }
                secondary={timerText(demand)}
              />

              {(demand.photos?.length || demand.bikeImage) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowSlides(demand)}>
                  <strong>voir les photos</strong>
                </Button>
              )}

              {demand.acceptedByRepairerUids &&
              demand.acceptedByRepairerUids.includes(uid) ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{marginLeft: 10}}
                  onClick={() => finallyRefuseDemand(demand.demandId, uid)}>
                  <strong>
                    Je ne suis plus disponible pour cette réparation
                  </strong>
                </Button>
              ) : (
                <>
                  {' '}
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{marginLeft: 10}}
                    onClick={() => acceptDemand(demand.demandId, uid)}>
                    <strong>valider</strong>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginLeft: 10}}
                    onClick={() => refuseDemand(demand.demandId, uid)}>
                    <strong>refuser</strong>
                  </Button>
                </>
              )}
            </ListItem>
          );
        })}
      </List>
    </MainContainer>
  );
};

export default ManualAppointments;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
