import React from 'react';
import styled from 'styled-components';
import ToolIcon from '../../assets/valors-icons/tool-primary.svg';
import GroupIcon from '../../assets/valors-icons/group-primary.svg';
import MeditationIcon from '../../assets/valors-icons/meditation-primary.svg';
import MustacheIcon from '../../assets/valors-icons/mustache-primary.svg';
import SatisfiedIcon from '../../assets/valors-icons/satisfied-primary.svg';
import ShopIcon from '../../assets/valors-icons/shop-primary.svg';
import StopHandIcon from '../../assets/valors-icons/stop-hand-primary.svg';
import BikeIcon from '../../assets/valors-icons/bike-primary.svg';
import WorldIcon from '../../assets/valors-icons/world-primary.svg';

const defValors = [
  {
    icon: ToolIcon,
    text:
      'on aime le savoir faire et la passion de nos réparateurs de vélo partenaires qui ont pignon sur rue. On met juste une petite touche de digitale dans le “process”.',
  },
  {
    icon: StopHandIcon,
    text:
      'on pense qu’acheter un vélo d’occasion c’est mieux qu’acheter un vélo neuf à condition de savoir ce que l’on achète. On vous propose l’intermédiation et le contrôle avant vente par un réparateur dans vos achats/ventes entre particuliers.',
  },
  {
    icon: ShopIcon,
    text:
      'on pense que c’est un peu plus sage de pousser la porte d’un réparateur/vendeur pour s’acheter un vélo ou de rencontrer un particuliers en bas de chez soi via notre marketplace de vélo d’occasion plutôt que de se faire livrer un vélo qui sort tout droit d’un entrepôt logistique à 500km de chez soi.',
  },
  {
    icon: GroupIcon,
    text:
      'on préfère répartir la valeur ajoutée chez une multitude de réparateurs entrepreneurs qui paient un pas de porte, achètent, revendent et emploient plutôt que de faire tourner des grosses machines ou enseignes, aussi sympa et efficaces soient-elles.',
  },
  {
    icon: WorldIcon,
    text:
      'on pense que la manière dont nous consommons aujourd’hui dessine le monde de demain.',
  },
  {
    icon: SatisfiedIcon,
    text:
      'on pense que rouler à vélo rend un petit peu plus heureux, le temps de la course, on divague, on phosphore, on crée, on relativise ou on exalte.',
  },
  {
    icon: MustacheIcon,
    text:
      'on aime la singularité et les rides des vélos d’occasion que l’on se transmet entre particuliers.',
  },
  {
    icon: BikeIcon,
    text: 'on est contre le transhumanisme mais pour la vie éternelle du vélo.',
  },
  {
    icon: MeditationIcon,
    text:
      'on sait que nous sommes perfectibles, et on espère que vous serez tolérants. On attend vos retours pour améliorer notre app chaque jour grâce à vous.',
  },
];
export default () => {
  return (
    <Valors>
      <Goat>
        <Triangle />
        <img
          src={require('../../assets/biquette.svg')}
          style={{height: '70px'}}
        />
        <p>
          Chez Biket,
          <br />
          on prononce Biket
          <br />
          comme biquette
        </p>
      </Goat>
      <h2
        id="valors"
        style={{
          fontFamily: "'Lobster', cursive",
          fontSize: '2.5rem',
          margin: 0,
          marginBottom: '40px',
          fontWeight: 400,
          color: '#164a39',
          paddingTop: '110px',
          marginTop: '-110px',
        }}>
        Biket, maison digitale pour vélo éternels.
      </h2>
      <h3
        style={{
          fontSize: '2rem',
        }}>
        Nos valeurs :
      </h3>
      {defValors.map(valor => {
        return <Valor {...valor} />;
      })}
    </Valors>
  );
};
const Valors = styled.div`
  position: relative;
  margin-top: 70px;
  width: 100vw;
  padding: 0 15vw;
  @media (max-width: 768px) {
    padding: 0 5vw;
    h2 {
      padding-top: 60px !important;
      margin-top: -60px !important;
    }
  }
`;
const Valor = ({textPrefix = 'Chez Biket', icon, text}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '1rem',
      }}>
      <img src={icon} alt="valor icon" />
      <p>
        <strong>{textPrefix}</strong> {text}
      </p>
    </div>
  );
};

const Triangle = styled.div`
  width: 30px;
  height: 0;
  border: 0 solid transparent;
  border-top-width: 35px;
  border-bottom-width: 35px;
  border-left: 20px solid #fafafa;
  background-color: #164a39;
  position: absolute;
  left: -30px;
  top: 10px;
`;

const Goat = styled.div`
  padding: 0 1.5rem 0 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 1rem;
  background: #00d18b;
  color: #ffffff;
  gap: 1rem;
  height: 90px;
  p {
    font-family: Lobster;
    font-size: 1.5rem;
    margin: 0;
    line-height: 1.2rem;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;
