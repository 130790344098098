import React, {
  MouseEvent,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';

const Slides = [
  {
    text: 'Je cherche un vélo d’occasion à proximité de chez moi',
    image: '01.png',
    color: '#00D18B',
  },
  {
    text: 'J’achète un vélo contrôlé et cértifié par un réparateur pro.',
    image: '02.png',
    color: '#00D18B',
  },
  {
    text: 'Je retrouve tous mes vélos dans mon profil.',
    image: '03.png',
    color: '#FF001E',
  },
  {
    text:
      'Je répars mon vélo en choisissant mon réparateur à proximité de chez moi.',
    image: '04.png',
    color: '#FFA600',
  },
  {
    text:
      'Je valide le devis et je suis informé à chaque étape de la réparation',
    image: '05.png',
    color: '#FFA600',
  },
  {
    text:
      'Je retrouve le détail de la réparation dans le “Carnet d’entretien digital”.',
    image: '06.png',
    color: '#FF001E',
  },
  {
    text:
      'Je revends mon vélo à un particulier et la roue continue de tourner',
    image: '07.png',
    color: '#FF7800',
  },
];

interface StateCaroselParams {
  width_carosel?: number;
  width_childrens?: number;
  qnt_childrens?: number;
  max_width_carousel?: number;
}
export default () => {
  const Carousel = useRef<HTMLDivElement>(null);
  const [stateCarosel, setStateCarousel] = useState<StateCaroselParams>();

  const toggleSlider = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    switch (e.currentTarget.id) {
      case 'next':
        return (Carousel.current!.scrollLeft += stateCarosel?.width_childrens!);

      case 'prev':
        return (Carousel.current!.scrollLeft -= stateCarosel?.width_childrens!);

      default:
        return null;
    }
  };

  const handleCarousel = useCallback(() => {
    if (Carousel.current) {
      const carousel = Carousel.current;
      setStateCarousel({
        ...stateCarosel,
        width_carosel: carousel.clientWidth,
        qnt_childrens: carousel.children.length,
        width_childrens: carousel.children.item(0)?.clientWidth,
        max_width_carousel:
          (carousel.children.length - 1) *
          carousel.children.item(0)?.clientWidth!,
      });
    }
  }, [setStateCarousel]);
  useEffect(() => handleCarousel(), [handleCarousel]);

  // console.log(Carousel?.current?.scrollLeft);

  return (
    <>
      <Container ref={Carousel}>
        {Slides.map((slide, index) => {
          return (
            <Slide
              style={{
                backgroundImage: `linear-gradient(to bottom, #FAFAFA 50%, ${slide.color} 50%)`,
              }}>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 700,
                  lineHeight: '1.5rem',
                  fontSize: '1rem',
                }}>
                {slide.text}
              </p>
              <img
                src={require(`../../assets/screenMobil/${slide.image}`)}
                alt=""
              />
            </Slide>
          );
        })}
      </Container>
      <Controls>
        <Arrow className="left" onClick={toggleSlider} id="prev" />
        <Arrow className="right" onClick={toggleSlider} id="next" />
      </Controls>
    </>
  );
};

const Controls = styled.div`
  position: relative;
  width: 100vw;
  pointer-events: none;
  background-color: red;
`;

const Arrow = styled.button`
  background-image: url(${require('../../assets/arrow.svg')});
  background-repeat: no-repeat;
  background-position: 25% center;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 1rem;
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  transition: transform 0.3s ease-in-out;
  top: -200px;
  border: none;
  &.left {
    left: -40px;
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.1);
    }
  }
  &.right {
    right: -40px;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  height: 600px;
  width: 100%;
  margin-top: 2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  min-width: 25vw;
  max-width: 25vw;
  padding: 0 4vw;
  @media (max-width: 1200px) {
    min-width: 50vw;
    max-width: 50vw;
  }
  @media (max-width: 600px) {
    min-width: 100vw;
    max-width: 100vw;
  }
`;
