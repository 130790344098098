import React, {useEffect, useState} from 'react';
import {DayPickerRangeController} from 'react-dates';
import 'moment/locale/fr';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {Button, IconButton, Snackbar} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import uuid from 'uuid';

import {addAnnualClosures, getCalendar} from '../calendar/calendarActions';
import {useUser} from '../auth/userContext';
import FullPageSpinner from '../util/FullPageSpinner';

export default function AnnualClosures() {
  moment.locale('fr');

  const {uid} = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [focused, setFocused] = useState<'startDate' | 'endDate'>('startDate');
  const [toastMessage, setToastMessage] = useState('');
  const [start, setStartDate] = useState<any>(moment());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [annualClosures, setAnnualClosures] = useState<any[]>([]);

  useEffect(() => {
    async function initData() {
      const alreadyExistingClosures = (await getCalendar(uid)).filter(
        f => f.resource.isAnnualClosure,
      );
      setAnnualClosures(alreadyExistingClosures);
      setIsLoading(false);
    }
    initData();
  }, [uid]);

  const onDatesChange = async ({startDate, endDate}) => {
    setStartDate(startDate);

    if (startDate && endDate) {
      const newClosures = annualClosures.concat({
        start: startDate.toDate(),
        end: endDate.toDate(),
        title: 'Congès annuel',
        resource: {eventUid: uuid.v4(), isAnnualClosure: true},
      });
      setAnnualClosures(newClosures);
      setStartDate(moment());
      setFocused('startDate');
      setShowDatePicker(false);
      try {
        await addAnnualClosures({repairerUid: uid, events: newClosures});
        setToastMessage('Enregistré');
      } catch (e) {
        setToastMessage("Une erreur s'est produite");
      }
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastMessage('');
  };

  const handleDelete = async index => {
    const annualClosuresCopy = annualClosures;
    annualClosuresCopy.splice(index, 1);
    setAnnualClosures(annualClosuresCopy);
    try {
      await addAnnualClosures({repairerUid: uid, events: annualClosuresCopy});
      setToastMessage('Supprimé');
    } catch (e) {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const displayDate = ({start, end}) => {
    return (
      'Du ' + moment(start).format('LL') + ' au ' + moment(end).format('LL')
    );
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <List style={{maxWidth: 500}}>
        {annualClosures.map((c, i) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DateRangeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={displayDate(c)} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete">
                <DeleteOutlineIcon
                  onClick={() => {
                    handleDelete(i);
                  }}
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <p style={{justifyContent: 'center', display: 'flex'}}>
        <Button
          style={{color: 'white'}}
          onClick={() => setShowDatePicker(true)}
          variant="contained"
          color="primary">
          Ajouter une nouvelle fermeture annuelle
        </Button>
      </p>

      {showDatePicker && (
        <DayPickerRangeController
          numberOfMonths={window.innerWidth < 600 ? 1 : 2}
          onFocusChange={focused => focused && setFocused(focused)}
          focusedInput={focused}
          hideKeyboardShortcutsPanel
          startDate={start}
          endDate={null}
          onDatesChange={onDatesChange}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message={toastMessage}
        action={
          <>
            <Button color="primary" size="small" onClick={handleClose}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
}
