import isDev from "../auth/isDev";

let cloudFunctionsBaseUrl: string;

if (isDev)
  cloudFunctionsBaseUrl = 'http://localhost:5001/biket-62bb2/us-central1/'
else if (process.env.REACT_APP_PID && process.env?.REACT_APP_PID?.indexOf('staging') >= 0) // "staging" in project id, so is staging environnement
  cloudFunctionsBaseUrl = 'https://us-central1-staging-biket-f880c.cloudfunctions.net/'
else // environnement is prod
  cloudFunctionsBaseUrl = 'https://us-central1-biket-62bb2.cloudfunctions.net/'


console.log(cloudFunctionsBaseUrl)
export default cloudFunctionsBaseUrl;

