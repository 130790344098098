import React from 'react';
import uuid from 'uuid';
import firebase from 'firebase';
import {useState} from 'react';
import styled from 'styled-components';
import {Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import colors from '../theme/colors';
import isDev from '../auth/isDev';

export default ({images, onUploadAction, imagePrefix, style = {}}) => {
  const hiddenFileInputs: any[] = [
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
  ];

  const [areImagesUploading, setAreImagesUploading] = useState(false);

  const addPhoto = async (e, photoIndex) => {
    if (isDev) {
      const downloadUrl =
        'https://firebasestorage.googleapis.com/v0/b/biket-62bb2.appspot.com/o/bike.png?alt=media&token=9cd09168-fffa-4146-9ef1-b8fb836ed4fd';
      await onUploadAction(downloadUrl, photoIndex);
      setAreImagesUploading(false);
      return;
    }

    const file = e.target.files[0];

    try {
      const ref = firebase
        .storage()
        .ref()
        .child(`${imagePrefix}_${file.name}___${uuid.v4()}`);

      const uploadTask = ref.put(file);
      uploadTask.on('state_changed', console.log, console.error, () => {
        ref.getDownloadURL().then(async url => {
          await onUploadAction(url, photoIndex);
          setAreImagesUploading(false);
        });
      });
    } catch {
      setAreImagesUploading(false);
    }
  };

  const handleClick = (event, photoIndex) => {
    setAreImagesUploading(photoIndex);
    hiddenFileInputs[photoIndex].current.click(event, photoIndex);
  };

  return (
    <MainContainer style={style}>
      <PictureSquareContainer>
        {images.map((photoUrl, i) => (
          <PictureSquare key={i}>
            <input
              type="file"
              ref={hiddenFileInputs[i]}
              style={{display: 'none'}}
              onChange={e => addPhoto(e, i)}
            />
            {areImagesUploading !== i ? (
              <PressableSquare onClick={e => handleClick(e, i)}>
                {photoUrl ? (
                  <SImg src={photoUrl} alt="bike photo" />
                ) : (
                  <CameraIcon
                    src={require('../assets/camera.png')}
                    alt="Camera icon"
                  />
                )}
              </PressableSquare>
            ) : (
              <CircularProgress color={'primary'} />
            )}
          </PictureSquare>
        ))}
      </PictureSquareContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const containerMargin = 18;
const containerMarginWithPx = containerMargin + 'px';
const PictureSquareContainer = styled(Grid)`
  max-width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: ${containerMarginWithPx};
  margin-right: ${containerMarginWithPx};
  flex-wrap: wrap;
`;

const pictureMargin = 12;
const PictureSquare = styled.div`
  background-color: ${colors.greyA100};
  width: 160px;
  height: 160px;
  margin-top: ${pictureMargin + 'px'};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const PressableSquare = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
`;

const CameraIcon = styled.img`
  width: 80px;
  height: 70px;
`;

const SImg = styled.img`
  height: 100%;
  width: 100%;
`;
