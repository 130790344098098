import React, {useEffect, useState} from 'react';
import {IconButton, Snackbar} from '@material-ui/core';
import moment from 'moment';
import {Calendar, momentLocalizer, Event} from 'react-big-calendar';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import uuid from 'uuid';
import 'moment/locale/fr';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import _ from 'lodash';

import '../util/ReactotronConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../theme/styles.css';
import useWindowSize from '../util/useWindowSize';
import {useUser} from '../auth/userContext';
import {saveCalendar, getCalendar} from './calendarActions';
import CloseIcon from '@material-ui/icons/Close';
import FullPageSpinner from '../util/FullPageSpinner';
import colors from '../theme/colors';
import {isCloseDay} from '../account/OpenHours';
import EventModal from './EventModal';
import {getUserByEmail} from '../users/usersActions';
import {createNewUser} from '../auth/RegisterActoins';
import {createUserBike} from '../bikes/bikeActions';
import bikeTypes from '../bikes/bikeTypes';
import {createReparation} from '../reparations/reparationsActions';
import reparationStatutes from '../reparations/reparationStatutes';
import firebase from 'firebase';
import {getRepairer} from "../account/accountActions";

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default () => {
  const localizer = momentLocalizer(moment);

  const classes = useStyles();
  const windowSize = useWindowSize();
  const {uid, openHours, calendarSlotDuration} = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState<Event[]>([]);
  const [event, setEvent] = useState<Event | undefined>();
  const [minAndMaxHour, setMinAndMaxHour] = useState<any>();
  const [isEventModalOpened, setIsEventModalOpened] = useState(false);
  const [draggedEvent, setDraggedEvent] = useState<any>();
  const [toastMessage, setToastMessage] = useState('');

  const repairer = firebase.auth().currentUser?.toJSON();

  useEffect(() => {
    async function initData() {
      const o = openHours && JSON.parse(openHours!);

      if (o) {
        const hours = _.flattenDeep(Object.values(o));

        const max = Math.max(...hours);
        const maxForCalendar =
          Math.trunc(max) === max || Math.trunc(max) === 23 ? max : max + 1;

        setMinAndMaxHour({
          min: Math.trunc(Math.min(...hours.filter((h: number) => h !== 0))),
          max: maxForCalendar,
        });
      } else {
        setMinAndMaxHour({
          min: 8,
          max: 18,
        });
      }

      setEventsList(await getCalendar(uid));
      setIsLoading(false);
    }
    initData();
  }, [uid, openHours]);

  const handleSelect = ({start, end}) => {
    setIsEventModalOpened(true);
    setEvent({
      start,
      end,
    });
  };

  const onSelectEvent = (event: React.SetStateAction<Event | undefined>) => {
    setIsEventModalOpened(true);
    setEvent(event);
  };

  const deleteEvent = async () => {
    const newEvents = eventsList.filter(
      existingEvent =>
        existingEvent.resource.eventUid !== event!.resource.eventUid,
    );
    await saveEvents(newEvents, 'Tâche supprimée');
    setEventsList(newEvents);
    setEvent(undefined);
    setIsEventModalOpened(false);
  };
  // @ts-ignore
  const upsertEvent = async () => {
    console.log(event);

    if (event) {
      let newEvents: Event[];
      let user = await getUserByEmail(event.resource.user.email);
      if (user) {
        event.resource.user = user;
      } else {
        user = await createNewUser(event.resource.user);
      }

      // console.log(user);

      const bike = await createUserBike(user, {
        isElectric: false,
        gender: 'man',
        bikeType: bikeTypes.city,
        name: 'Vélo crée par votre réparateur',
        isOnSale: false
      });
      const bikeEntryCode = Math.floor(Math.random() * (10000 - 1000) + 1000);
      const bikeExitCode = Math.floor(Math.random() * (10000 - 1000) + 1000);
      createReparation({
        // @ts-ignore
        repairer: await getRepairer(repairer.uid),
        // @ts-ignore
        repairerUid: repairer.uid,
        userUid: user.uid,
        user,
        isElectric: false,
        ...(bike && {bike}),
        ...(bike?.uid && {bikeUid: bike.uid}),
        bikeExitCode,
        bikeEntryCode,
        status: reparationStatutes.waitingForEstimate,
        problem: event.resource.problem,
        start: new Date() // Todo : delete "start" attribut (this code is tmp patch for flow in mobile before patch on mobile)
      });

      if (event.resource && event.resource.eventUid) {
        newEvents = eventsList.map(existingEvent => {
          return existingEvent.resource.eventUid === event.resource.eventUid
            ? {...existingEvent, ...event}
            : existingEvent;
        });
      } else {
        const eventWithUid = {
          ...event,
          resource: {...event!.resource, eventUid: uuid.v4()},
        };
        newEvents = eventsList.concat(eventWithUid as Event);
      }

      await saveEvents(newEvents);
    }
    setEvent(undefined);
    setIsEventModalOpened(false);
  };

  const saveEvents = async (events: Event[], toastMessage?: string) => {
    const eventsCopy = eventsList;
    try {
      setEventsList(events);

      await saveCalendar({events, repairerUid: uid});
      setToastMessage(toastMessage ? toastMessage : 'Tâche enregistrée');
    } catch (e) {
      setToastMessage("Erreur: la tâche n'a pas pu être enregistrée");
      setEventsList(eventsCopy);
    }
  };

  /**
   * It takes a newTitle object as an argument, and if the newTitle object has a target property and a
   * value property, it sets the event's title property to the value of the target property
   * @param newTitle - {
   */
  const onEventTitleChange = (newTitle: {
    persist: () => void;
    target: {value: string};
  }) => {
    newTitle.persist();

    if (newTitle.target && newTitle.target.value !== '') {
      setEvent(e => ({...e, title: newTitle.target.value}));
    }
  };

  /**
   * It takes a new description value and sets the event's description to that value
   * @param newDesc - {
   */
  const onEventDescriptionChange = (newDesc: {
    persist: () => void;
    target: {value: string};
  }) => {
    newDesc.persist();

    if (newDesc.target && newDesc.target.value !== '') {
      setEvent(e => ({
        ...e,
        resource: {...event?.resource, description: newDesc.target.value},
      }));
    }
  };

  /**
   * It takes a new problem value and sets the event's problem to that value
   * @param newProblem
   */
  const onEventProblemChange = (newProblem: {
    persist: () => void;
    target: {value: string};
  }) => {
    newProblem.persist();

    if (newProblem.target && newProblem.target.value !== '') {
      setEvent(e => ({
        ...e,
        resource: {...event?.resource, problem: newProblem.target.value},
      }));
    }
  };

  /**
   * It takes a new email user and sets the event's resource user's email to the new email user
   * @param newUserEmail
   */
  const onEventUserEmailChange = async (newUserEmail: {
    persist: () => void;
    target: {value: string};
  }) => {
    newUserEmail.persist();

    if (newUserEmail.target && newUserEmail.target.value !== '') {
      // if (verifExistUser) {
      //   setEvent(e => ({
      //     ...e,
      //     resource: {
      //       ...event!.resource,
      //       user: {...verifExistUser},
      //     },
      //   }));
      // } else {
      setEvent(e => ({
        ...e,
        resource: {
          ...event!.resource,
          user: {...event!?.resource?.user, email: newUserEmail.target.value},
        },
      }));
      // }
    }
  };

  const onEventUserFirstNameChange = (newUserFirstName: {
    persist: () => void;
    target: {value: string};
  }) => {
    newUserFirstName.persist();

    if (newUserFirstName.target && newUserFirstName.target.value !== '') {
      setEvent(e => ({
        ...e,
        resource: {
          ...event!.resource,
          user: {
            ...event!?.resource?.user,
            firstName: newUserFirstName.target.value,
          },
        },
      }));
    }
  };

  const onEventUserLastNameChange = (newUserLastName: {
    persist: () => void;
    target: {value: string};
  }) => {
    newUserLastName.persist();

    if (newUserLastName.target && newUserLastName.target.value !== '') {
      setEvent(e => ({
        ...e,
        resource: {
          ...event!.resource,
          user: {
            ...event!?.resource?.user,
            lastName: newUserLastName.target.value,
          },
        },
      }));
    }
  };

  const moveOrResizeEvent = async ({event, start, end}) => {
    const newEvents = eventsList.map(existingEvent => {
      return existingEvent.resource.eventUid === event.resource.eventUid
        ? {...existingEvent, start, end}
        : existingEvent;
    });

    await saveEvents(newEvents);
  };

  const onDragStart = (event: any) => {
    setDraggedEvent(event);
  };

  const onDropFromOutside = async ({start, end, allDay}) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay: allDay,
    };
    setDraggedEvent(null);
    await moveOrResizeEvent({event, start, end});
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastMessage('');
  };

  const eventStyleGetter = () => {
    const style = {
      backgroundColor: colors.secondary,
      borderRadius: '0px',
      opacity: 0.9,
      color: 'white',
      fontSize: 24,
      border: '0px',
      display: 'block',
    };

    return {
      style: style,
    };
  };

  const CustomEvent = (event: {event: any}) => {
    const e = event.event;

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <strong style={{fontSize: 18}}> {e.title} </strong>
        <p style={{fontSize: 14}}>{e.resource && e.resource.description}</p>
      </div>
    );
  };

  const components = {
    event: CustomEvent,
  };

  const dayPropGetter = (date: Date) => {
    if (isCloseDay(moment(date).day(), openHours && JSON.parse(openHours))) {
      return {style: {backgroundColor: colors.greyA100}};
    }
    return {};
  };

  const isInRanges = (number: number, intervals: number[][]): boolean => {
    let res: boolean = false;

    for (const i of intervals) {
      if (_.inRange(number, i[0], i[1])) {
        res = true;
        break;
      }
    }
    return res;
  };

  const slotPropGetter = (date: Date) => {
    const dayAvailability =
      openHours && JSON.parse(openHours)[moment(date).day()];

    if (!dayAvailability) {
      return {style: {backgroundColor: colors.greyA100, borderTop: 0}};
    }
    if (
      !isInRanges(
        moment(date).hour() + moment(date).minutes() / 100,
        dayAvailability,
      )
    ) {
      return {style: {backgroundColor: colors.greyA100, borderTop: 0}};
    }

    return {};
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <div className={classes.container}>
      <EventModal
        event={event}
        setEvent={setEvent}
        isEventModalOpened={isEventModalOpened}
        openModal={setIsEventModalOpened}
        deleteEvent={deleteEvent}
        upsertEvent={upsertEvent}
        onEventTitleChange={onEventTitleChange}
        onEventDescriptionChange={onEventDescriptionChange}
        onEventProblemChange={onEventProblemChange}
        onEventUserEmailChange={onEventUserEmailChange}
        onEventUserFirstNameChange={onEventUserFirstNameChange}
        onEventUserLastNameChange={onEventUserLastNameChange}
      />

      <DragAndDropCalendar
        selectable
        resizable
        components={components}
        dayPropGetter={dayPropGetter}
        slotPropGetter={slotPropGetter}
        onEventDrop={moveOrResizeEvent}
        onEventResize={moveOrResizeEvent}
        onDragStart={onDragStart}
        onDropFromOutside={onDropFromOutside}
        defaultDate={moment().toDate()}
        defaultView="week"
        events={eventsList}
        localizer={localizer}
        step={calendarSlotDuration}
        startAccessor="start"
        endAccessor="end"
        style={{height: windowSize.height}}
        min={moment().startOf('day').hour(minAndMaxHour.min).minute(0).toDate()}
        max={moment().startOf('day').hour(minAndMaxHour.max).minute(0).toDate()}
        onSelectSlot={handleSelect}
        onSelectEvent={onSelectEvent}
        messages={{
          month: 'Mois',
          day: 'Jour',
          week: 'Semaine',
          today: "Aujourd'hui",
          previous: 'Précedent',
          next: 'Suivant',
        }}
        eventPropGetter={eventStyleGetter}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message={toastMessage}
        action={
          <>
            <Button color="primary" size="small" onClick={handleClose}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
}));
