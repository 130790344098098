import client from '../util/fetchClient';
import {SEND_NOTIFICATION} from '../firebase/cloudFunctionsRoutes';
import {getUser} from '../users/usersActions';
import firebase from 'firebase';

export const sendNotification = async (
  userUid: string,
  title: string,
  message: string,
) => {
  if (!userUid) return;
  return client(SEND_NOTIFICATION(userUid, title, message));
};

export const sendBikeEntryNotification = (userUid: string) => {
  const title = 'Vélo reçu';
  const message = 'Le réparateur a indiqué avoir reçu votre vélo';
  return sendNotification(userUid, title, message);
};

export const sendEstimateDoneNotification = async (
  userUid: string,
  reparationUid: string,
  estimateLines: any,
  total: number,
) => {
  const repairer = firebase.auth().currentUser?.toJSON();
  const user = await getUser(userUid);
  const sendEmailGmail = firebase.functions().httpsCallable('sendEmailGmail');
  if (!user?.verified) {
    await sendEmailGmail({
      user,
      repairer,
      reparationUid,
      devis: estimateLines,
      total,
      type: 'devis',
    }).then(result => {
      console.log(result);
    })
      .catch((error) => console.error(`CATCH sendEmailGmail() in ${sendEstimateDoneNotification.name}():`,error));
  }
  const title = 'Devis reçu';
  const message = 'Le réparateur vous a envoyé un devis';
  return sendNotification(userUid, title, message);
};

export const sendReparationDoneNotification = async (
  userUid: string,
  entry: any,
) => {
  const user = await getUser(userUid);
  const sendEmailGmail = firebase.functions().httpsCallable('sendEmailGmail');
  if (!user?.verified) {
    await sendEmailGmail({
      user,
      code: entry.bikeExitCode,
      type: 'recuperation',
    }).then(result => {
      console.log(result);
    })
      .catch((error) => console.error(`CATCH sendEmailGmail() in ${sendReparationDoneNotification.name}():`,error));
  }

  const title = 'Réparation terminée';
  const message = 'Votre vélo est réparé, vous pouvez le récupérer';
  return sendNotification(userUid, title, message);
};

export const sendControlDoneWithProceduresNotification = (userUid: string) => {
  const title = 'Contrôle terminée';
  const message =
    'Votre vélo a été contrôlé, le réparateur vous a proposé des interventions optionnelles';
  return sendNotification(userUid, title, message);
};

export const sendControlDoneNotification = (userUid: string) => {
  const title = 'Contrôle terminée';
  const message = 'Votre vélo a été contrôlé, vous pouvez le récupérer';
  return sendNotification(userUid, title, message);
};
