import React, {useState} from 'react';

import {
  Button,
  IconButton,
  Snackbar,
  Switch,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import colors from '../theme/colors';
import {updateRepairer} from './accountActions';
import {useUser, useUserDispatch} from '../auth/userContext';
import styled from 'styled-components';

const defaultMechanicalOverhaulPrice = 39;
const defaultElectricOverhaulPrice = 69;
const defaultMechanicalOverhaulDuration = 40;
const defaultElectricOverhaulDuration = 60;

export default () => {
  const {uid, appointmentSettings} = useUser();

  const userDispatch = useUserDispatch();

  const [toastMessage, setToastMessage] = useState('');

  // const handleAutomaticSwitchChange = async () => {
  //   let newSettings;
  //   if (!appointmentSettings) {
  //     newSettings = {
  //       automatic: true,
  //     };
  //   } else if (!appointmentSettings.automatic) {
  //     newSettings = {
  //       ...appointmentSettings,
  //       automatic: true,
  //     };
  //   } else {
  //     newSettings = {
  //       ...appointmentSettings,
  //       automatic: !appointmentSettings.automatic,
  //     };
  //   }
  //   userDispatch({appointmentSettings: newSettings});
  //   try {
  //     await updateRepairer(uid, {appointmentSettings: newSettings});
  //     setToastMessage('Enregistré');
  //   } catch {
  //     setToastMessage("Une erreur s'est produite");
  //   }
  // };

  const handleUrgencySwitchChange = async () => {
    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        urgency: true,
      };
    } else if (!appointmentSettings.urgency) {
      newSettings = {
        ...appointmentSettings,
        urgency: true,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        urgency: !appointmentSettings.urgency,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleAcceptElectricSwitchChange = async () => {
    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        acceptElectric: true,
      };
    } else if (!appointmentSettings.acceptElectric) {
      newSettings = {
        ...appointmentSettings,
        acceptElectric: true,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        acceptElectric: !appointmentSettings.acceptElectric,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleAutomaticAppointmentSwitchChange = async () => {
    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        automaticAppointment: false,
      };
    } else if (appointmentSettings.automaticAppointment === undefined) {
      newSettings = {
        ...appointmentSettings,
        automaticAppointment: false,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        automaticAppointment: !appointmentSettings.automaticAppointment,
      };
    }

    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleUrgencyEvenIfNonMatchingSwitchChange = async () => {
    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        urgencyEvenIfNonMatching: true,
      };
    } else if (!appointmentSettings.urgencyEvenIfNonMatching) {
      newSettings = {
        ...appointmentSettings,
        urgencyEvenIfNonMatching: true,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        urgencyEvenIfNonMatching: !appointmentSettings.urgencyEvenIfNonMatching,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleAcceptOverhaulSwitchChange = async () => {
    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        acceptOverhaul: true,
      };
    } else if (!appointmentSettings.acceptOverhaul) {
      newSettings = {
        ...appointmentSettings,
        acceptOverhaul: true,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        acceptOverhaul: !appointmentSettings.acceptOverhaul,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const onMechanicalOverhaulPriceChange = async newInput => {
    const mechanicalOverhaulPrice = Number(newInput.target.value);

    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        mechanicalOverhaulPrice,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        mechanicalOverhaulPrice,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const onElectricOverhaulPriceChange = async newInput => {
    const electricOverhaulPrice = Number(newInput.target.value);

    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        electricOverhaulPrice,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        electricOverhaulPrice,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const onMechanicalOverhaulDurationChange = async newInput => {
    const mechanicalOverhaulDuration = Number(newInput.target.value);

    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        mechanicalOverhaulDuration,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        mechanicalOverhaulDuration,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const onElectricOverhaulDurationChange = async newInput => {
    const electricOverhaulDuration = Number(newInput.target.value);

    let newSettings;
    if (!appointmentSettings) {
      newSettings = {
        electricOverhaulDuration,
      };
    } else {
      newSettings = {
        ...appointmentSettings,
        electricOverhaulDuration,
      };
    }
    userDispatch({appointmentSettings: newSettings});
    try {
      await updateRepairer(uid, {appointmentSettings: newSettings});
      setToastMessage('Enregistré');
    } catch {
      setToastMessage("Une erreur s'est produite");
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastMessage('');
  };

  return (
    <div>
      {/*<LabelAndSwitch>*/}
      {/*  <SwitchLabel>*/}
      {/*    J'accepte les rendez-vous de manière automatique si mes créneaux*/}
      {/*    matchent avec les demandes*/}
      {/*  </SwitchLabel>*/}

      {/*  <Line />*/}

      {/*  <Switch*/}
      {/*    checked={*/}
      {/*      appointmentSettings && appointmentSettings.automatic*/}
      {/*        ? appointmentSettings.automatic*/}
      {/*        : false*/}
      {/*    }*/}
      {/*    onChange={handleAutomaticSwitchChange}*/}
      {/*    color="primary"*/}
      {/*  />*/}
      {/*</LabelAndSwitch>*/}

      <LabelAndSwitch>
        <SwitchLabel>J'accepte les vélos éléctriques</SwitchLabel>

        <Line />

        <Switch
          checked={
            appointmentSettings && appointmentSettings.acceptElectric
              ? appointmentSettings.acceptElectric
              : false
          }
          onChange={handleAcceptElectricSwitchChange}
          color="primary"
        />
      </LabelAndSwitch>

      <LabelAndSwitch>
        <SwitchLabel>
          J’accepte la validation automatique des rdv sollicités par les clients
          quand ils matchent avec les disponibilités de mon agenda
        </SwitchLabel>

        <Line />

        <Switch
          checked={appointmentSettings?.automaticAppointment !== false}
          onChange={handleAutomaticAppointmentSwitchChange}
          color="primary"
        />
      </LabelAndSwitch>

      <LabelAndSwitch>
        <SwitchLabel>
          J'accepte les demandes pour réparation immédiate quand elles matchent
          avec une disponibilité en ligne immédiate. Je dois la contre valider
          dans les 5 minutes
        </SwitchLabel>

        <Line />

        <Switch
          checked={
            appointmentSettings && appointmentSettings.urgency
              ? appointmentSettings.urgency
              : false
          }
          onChange={handleUrgencySwitchChange}
          color="primary"
        />
      </LabelAndSwitch>

      <LabelAndSwitch>
        <SwitchLabel>
          J'accepte de recevoir les demandes de réparation immédiates même si
          elles ne matchent pas avec mes dispos en ligne. Je dois la valider
          dans les 5 minutes
        </SwitchLabel>

        <Line />

        <Switch
          checked={
            appointmentSettings && appointmentSettings.urgencyEvenIfNonMatching
              ? appointmentSettings.urgencyEvenIfNonMatching
              : false
          }
          onChange={handleUrgencyEvenIfNonMatchingSwitchChange}
          color="primary"
        />
      </LabelAndSwitch>

      <LabelAndSwitch>
        <SwitchLabel>J'accepte de proposer la révision certifiée </SwitchLabel>

        <Line />

        <Switch
          checked={
            appointmentSettings && appointmentSettings.acceptOverhaul
              ? appointmentSettings.acceptOverhaul
              : false
          }
          onChange={handleAcceptOverhaulSwitchChange}
          color="primary"
        />
      </LabelAndSwitch>

      {appointmentSettings && appointmentSettings.acceptOverhaul && (
        <>
          <LabelAndInput>
            <InputLabel>
              Prix de la révision certifiée d'un vélo mécanique
            </InputLabel>

            <Line />

            <TextField
              defaultValue={defaultMechanicalOverhaulPrice}
              type={'number'}
              onChange={onMechanicalOverhaulPriceChange}
            />
          </LabelAndInput>

          <LabelAndInput>
            <InputLabel>
              Prix de la révision certifiée d'un vélo électrique
            </InputLabel>

            <Line />

            <TextField
              defaultValue={defaultElectricOverhaulPrice}
              type={'number'}
              onChange={onElectricOverhaulPriceChange}
            />
          </LabelAndInput>

          <LabelAndInput>
            <InputLabel>Temps d'une révision d'un vélo mécanique</InputLabel>

            <Line />

            <TextField
              defaultValue={defaultMechanicalOverhaulDuration}
              type={'number'}
              onChange={onMechanicalOverhaulDurationChange}
            />
          </LabelAndInput>

          <LabelAndInput>
            <InputLabel>
              Temps d'une révision d'un vélo mécanique électrique
            </InputLabel>

            <Line />

            <TextField
              defaultValue={defaultElectricOverhaulDuration}
              type={'number'}
              onChange={onElectricOverhaulDurationChange}
            />
          </LabelAndInput>
        </>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message={toastMessage}
        action={
          <>
            <Button color="primary" size="small" onClick={handleClose}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

const LabelAndSwitch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
`;

const SwitchLabel = styled.p`
  max-width: 850px;
  color: ${colors.greyA500};
`;

const LabelAndInput = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const InputLabel = styled.span`
  color: ${colors.greyA500};
  align-self: flex-end;
  font-size: 16px;
  line-height: 14px;
`;

const Line = styled.span`
  color: ${colors.greyA500};
  flex: 1;
  display: flex;
  border-bottom: 1px solid ${colors.greyA200};
  margin-left: 10px;
  margin-right: 20px;
  min-width: 20px;
`;
