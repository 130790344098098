// In Account Settings

import {ReparationDurations} from '../auth/userContext';

export const defaultDurations: ReparationDurations = {
  flatTire: 15,
  brakes: 30,
  gears: 30,
  overhaul: 30,
  other: 30,
  controlBeforeSale: 15,
};
