/**
 * possibleProblems list element logic :
 * first index in element = "problems" key (in english)
 * second index in element = "problems" value (in french)
 */
export const possibleProblems = [
  ['flatTire', 'Pneu crevé'],
  ['brakes', 'Problème de freins'],
  ['gears', 'Problème de vitesse'],
  ['overhaul', 'Révision'],
  ['other', 'Autre...'],
];

export const controlBeforeSale = 'controlBeforeSale';

const problems = {
  flatTire: 'Pneu crevé',
  brakes: 'Problème de freins',
  gears: 'Problème de vitesse',
  overhaul: 'Révision',
  other: 'Autre...',
  [controlBeforeSale]: 'Contrôle avant vente',
};

export default problems;
