const translations = {
  tires: 'Roues',
  changeTube: "Changement d'une chambre à air",
  changeSpecialBikeTube:
    "Changement d'une chambre à air sur vélo hollandais, électrique, cargo...",
  changeTire: "Changement d'un pneu",
  changeAntiPunctureTire: "Changement d'un pneu anti-crevaison",
  changeSpecialBikeTire:
    "Changement d'un pneu sur vélo hollandais, électrique, cargo...",
  changeSpecialBikeAntiPunctureTire:
    "Changement d'un pneu anti-crevaison sur vélo hollandais, électrique, cargo...",
  tireUnveiling: "Dévoilage d'une roue",
  changeHybridBikeFrontTire:
    "Changement d'une roue AV VTC (pneu et chambre à air non fournis)",
  changeHybridBikeRearTire:
    "Changement d'une roue AR VTC (transmission, pneu et chambre à air non fournis)",
  changeRoadBikeFrontTire:
    "Changement d'une roue AV Route (pneu et chambre à air non fournis)",
  changeRoadBikeRearTire:
    "Changement d'une roue AR Route (transmission, pneu et chambre à air non fournis)",
  brakes: 'Freinage',
  frontAndRear: 'Réglage des freins avants et arrières',
  changeBikeSkates: "Changement et réglage d'une paire de patins",
  changeBikeSkatesForHydraulicBrakes:
    "Changement et réglage d'une paire de patins pour freins hydrauliques",
  changeBrakeCable:
    "Changement d'un câble + gaine pour frein à patins (réglage inclus)",
  changeBrakeCableForDiscBrakes:
    "Changement d'un câble + gaine pour frein à disque (réglage inclus)",
  changeBrakeCaliperForSpecial:
    "Changement d'un étrier de frein v-brake, cantilever ou classique",
  changeBrakeCaliperForRoad: "Changement d'un étrier de frein route",
  changeBrakeLeverSpecial:
    "Changement d'un levier de frein (pour système v-brake, cantilever ou classique)",
  discBrakeAdjustment: 'Réglage freins à disques mécaniques AV et AR',
  changeBrakePad: "Changement d'une paire de plaquettes de freins",
  bleedingHydraulicDiscBrake:
    'Purge système frein à disque hydraulique (Frein AV ou AR)',
  bleedingHydraulicDiscBrakeOnRim:
    'Purge système freins hydrauliques sur jante (AV ou AR)',
  gears: 'Transmission',
  reglageDesVitesses: 'Réglage des vitesses',
  changementChaine1678: "Changement d'une chaîne 1/6/7/8 vitesses",
  changementChaine9: "Changement d'une chaîne 9 vitesses",
  changementChaine10: "Changement d'une chaîne 10 vitesses",
  changementRoueLibre:
    "Changement d'une roue libre ou cassette 5/6/7/8 vitesses",
  changementCassette: "Changement d'une cassette 9 vitesses",
  montagePedalier: "Changement d'un boîtier de pédalier",
  changementBoitier: "Changement d'un boîtier de pédalier",
  changementDerailleur: "Changement d'un dérailleur AV 3 plateaux",
  changementDerailleurExterne567:
    "Changement d'un dérailleur externe 5/6/7 vitesses",
  changementDerailleurExterne9: "Changement d'un dérailleur externe 9 vitesses",
  changementCablePlusGaine:
    "Changement d'un câble + gaine de dérailleur (réglage inclus)",
  changementManetteDerailleur:
    "Changement d'une manette de dérailleur (pièce non incluse)",
  changementManivelleGauche: "Changement d'une manivelle gauche",
  installationPairePedales:
    "Installation d'une paire de pédales (pédales incluses)",
  reglageJeuMoyeu: "Réglage du jeu d'un moyeu",
  electrique: 'Électrique',
  revisionComplete: "Révision complète d'un vélo à assistance électrique",
  diagnostique:
    'Diagnostic vélo à assistance électrique suite à un dysfonctionnement',
  miseAJour: 'Mise à jour logiciels',
  installationCartographie: 'Installation de cartographie (sur BOSCH)',
  changementCapteur: "Changement d'un capteur de pédalage (sans le capteur)",
  changementMoteur: "Changement d'un moteur (sans le moteur)",
  changementControleur: "Changement d'un contrôleur (sans le contrôleur)",
  miseEnServiceElectrique:
    "Mise en service d'un vélo à assistance électrique commandé sur Internet",
  changementConnectique:
    "Changement d'une connectique électrique (sans la connectique)",
  changementBatterie: 'Changement de batterie (sans la batterie)',
  priseEnChargeSAV:
    'Prise en charge SAV constructeur (batterie, moteur, contrôleur, capteur de pédalage etc)',
  etalonnageCapteur: 'Etalonnage du capteur de couple',
  overhaul: 'Révisions',
  pack: 'Pack entretien + graissage transmission',
  miseEnService: 'Mise en service vélo acheté sur Internet',
  nettoyage: 'Nettoyage et lubrification vélo',
  complete:
    'Révision complète : contrôle/serrage points de sécurité (freins, direction), graissage/contrôle transmission, réglage dérailleur, gonflage pneus',
  other: 'Autres',
  changementSelle: "Changement d'une selle ville (selle incluse)",
  installationPetitAccessoire: "Installation d'un petit accessoire",
  installationGrosAccessoire: "Installation d'un gros accessoire",
  installationGardeBoueClips:
    "Installation d'une paire de gardes-boue à clips (gardes-boue inclus)",
  installationGardeBoueTringles:
    "Installation d'une paire de gardes-boue à tringles (gardes-boue inclus)",
  remplacementGuidoline: 'Remplacement guidoline',
  remplacementTigeSelle: 'Remplacement tige de selle',
  decoupeCadenas: "Découpe de cadenas (pièce d'identité obligatoire)",
};

export default translations;
