import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, {useState} from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';
import ImageSlides from 'react-imageslides';

import {possibleProblems} from '../reparations/problems';

const EventModal = ({
  event,
  setEvent,
  isEventModalOpened,
  openModal,
  deleteEvent,
  upsertEvent,
  onEventTitleChange,
  onEventDescriptionChange,
  onEventProblemChange,
  onEventUserEmailChange,
  onEventUserFirstNameChange,
  onEventUserLastNameChange,
}) => {
  const [showSlides, setShowSlides] = useState(false);
  const [isEstimateSubmitted, setIsEstimateSubmitted] = useState<boolean>(false);

  const submit = async () => {
    setIsEstimateSubmitted(true)
    await upsertEvent();
    setIsEstimateSubmitted(false)
  }

  // console.log(event);
  if (!event) return <></>;

  const onPressEnter = (e: {keyCode: number}) => {
    if (e.keyCode === 13) {
      submit();
    }
  };

  const openSlides = () => {
    setShowSlides(true);
  };

  const problemPhotos = event.resource?.photos ? event.resource.photos : [];

  const photos = (event.resource?.bike
    ? [event.resource.bike.photoUrl].concat(problemPhotos)
    : problemPhotos
  ).filter(i => i);

  if (showSlides) {
    return (
      <ImageSlides
        index={0}
        tapClose={true}
        onClose={() => setShowSlides(false)}
        images={photos}
        isOpen={showSlides}
        showPageButton
        addon={() => {
          return (
            <a
              href="/#"
              style={{
                position: 'absolute',
                color: 'white',
                top: 10,
                right: 30,
                fontSize: 40,
                zIndex: 999,
                fontWeight: 'bold',
                height: 50,
                width: 150,
              }}
              onClick={() => setShowSlides(false)}>
              Fermer
            </a>
          );
        }}
      />
    );
  }

  return (
    <Dialog
      open={isEventModalOpened}
      onClose={() => {
        setEvent(undefined);
        openModal(false);
      }}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Réparation</DialogTitle>

      <DialogContent>
        <TextField
          defaultValue={event ? event!.title : ''}
          onKeyDown={onPressEnter}
          onChange={onEventTitleChange}
          autoFocus
          margin="dense"
          id="name"
          label="Titre"
          type="text"
          fullWidth
        />

        <TextField
          defaultValue={
            event && event.resource && event.resource.description
              ? event!.resource.description
              : ''
          }
          onKeyDown={onPressEnter}
          onChange={onEventDescriptionChange}
          margin="dense"
          label="Description falcutative"
          type="text"
          fullWidth
        />

        <FormControl fullWidth>
          <InputLabel id="problem-select-label">Problème</InputLabel>
          <Select
            labelId="problem-select-label"
            label="Problème"
            onChange={onEventProblemChange}
            defaultValue={event?.resource?.problem}
          >
            {possibleProblems.map((problem, index) => (
              <MenuItem key={index} value={problem[0]}>{problem[1]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogContent>
        <Typography component={'h3'}>Client</Typography>

        <TextField
          defaultValue={event.resource ? event.resource?.user?.email : ''}
          onKeyDown={onPressEnter}
          onChange={onEventUserEmailChange}
          margin="dense"
          id="email"
          label="Email"
          type="text"
          fullWidth
        />
        <TextField
          defaultValue={event.resource ? event.resource?.user?.firstName : ''}
          onKeyDown={onPressEnter}
          onChange={onEventUserFirstNameChange}
          margin="dense"
          id="firstName"
          label="Nom falcutative"
          type="text"
          fullWidth
        />
        <TextField
          defaultValue={event.resource ? event.resource?.user?.lastName : ''}
          onKeyDown={onPressEnter}
          onChange={onEventUserLastNameChange}
          margin="dense"
          id="lastName"
          label="Prenom falcutative"
          type="text"
          fullWidth
        />
      </DialogContent>

      {/* J'ai commenter cette parti car je comprend pas encore comment les mettre en lien dnas la réparations */}
      {/* <DialogContent>
        <Typography component={'h3'}>Réparation</Typography>

        {Object.keys(problems).map(key => {
          return (
            <FormControlLabel
              control={<Checkbox />}
              label={problems[key]}
              key={key}
            />
          );
        })}
      </DialogContent> */}

      {photos.length > 0 && (
        <DialogContent>
          <Link href="#" onClick={openSlides} style={{fontWeight: 'bold'}}>
            {photos.length === 1 && 'Voir la photo'}
            {photos.length > 1 && 'Voir les photos'}
          </Link>
        </DialogContent>
      )}

      <DialogActions style={{justifyContent: 'space-between'}}>
        {event && event!.resource && event!.resource.eventUid ? (
          <RedButton onClick={deleteEvent}>Supprimer la tâche</RedButton>
        ) : (
          <div />
        )}
        <>
          <Button onClick={() => openModal(false)} color="primary">
            Annuler
          </Button>
          <Button color="primary" onClick={submit} disabled={isEstimateSubmitted}>
            {!isEstimateSubmitted ? (
              <span>Valider</span>
            ) : (
              <CircularProgress/>
            )}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default EventModal;

const RedButton = styled(Button)`
  background-color: ${colors.palette2};
  color: white;
`;
