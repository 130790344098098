import firebase from 'firebase';

import {repairers} from '../firebase/firestoreCollections';

export const updateRepairer = (uid, newData) => {
  return firebase
    .firestore()
    .collection(repairers)
    .doc(uid)
    .update(newData);
};

export const getRepairer = (uid?: string) => {
  if (!uid) return;

  return firebase
    .firestore()
    .collection(repairers)
    .doc(uid)
    .get()
    .then(user => user.data());
};
