import styled from 'styled-components';
import React from 'react';
import Grid from '@material-ui/core/Grid';

import bikeTypes from '../bikes/bikeTypes';
import ElectricSwitch from './ElectricSwitch';
import colors from '../theme/colors';

export default ({
  selectedBikeTypes,
  onSelectBikeType,
  isElectric,
  setIsElectric,
}) => {
  const types = [
    {
      key: bikeTypes.city,
      label: 'à la ville',
      image: () => (
        <BikeImage alt="bike" src={require('../assets/bikes/cityBike.png')} />
      ),
      imageActive: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/cityBikeActive.png')}
        />
      ),
    },
    {
      key: bikeTypes.cityAndCountry,
      label: 'ville & champs',
      image: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/cityAndCountryBike.png')}
        />
      ),
      imageActive: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/cityAndCountryBikeActive.png')}
        />
      ),
    },
    {
      key: bikeTypes.minimalUrban,
      label: 'urbain minimaliste',
      image: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/minimalUrbanBike.png')}
        />
      ),
      imageActive: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/minimalUrbanBikeActive.png')}
        />
      ),
    },
    {
      key: bikeTypes.adventurer,
      label: 'baroudeur',
      image: () => (
        <BikeImage alt="bike" src={require('../assets/bikes/adventurer.png')} />
      ),
      imageActive: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/adventurerActive.png')}
        />
      ),
    },
    {
      key: bikeTypes.inAHurry,
      label: 'très pressé',
      image: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/inAHurryBike.png')}
        />
      ),
      imageActive: () => (
        <BikeImage
          alt="bike"
          src={require('../assets/bikes/inAHurryBikeActive.png')}
        />
      ),
    },
  ];

  const renderTypes = (item, index) => {
    const isSelected = selectedBikeTypes.indexOf(item.key) !== -1;

    return (
      <Grid item>
        <Bike
          key={index}
          style={{
            backgroundColor: isSelected ? colors.primary : colors.greyA100,
          }}
          onClick={() => onSelectBikeType(item.key)}>
          {isSelected ? item.imageActive() : item.image()}

          <div style={{marginBottom: 15}}>
            {isSelected ? (
              <Bold>{item.label}</Bold>
            ) : (
              <span
                style={{color: colors.greyA400, fontSize: 18, marginTop: 12}}>
                {item.label}
              </span>
            )}
          </div>
        </Bike>
      </Grid>
    );
  };

  return (
    <MainContainer>
      <BikesContainer
        container
        spacing={4}
        justify="space-evenly"
        alignItems="center">
        {types.map(renderTypes)}

        <Grid item style={{}}>
          <ElectricSwitch
            isElectric={isElectric}
            setIsElectric={setIsElectric}
          />
        </Grid>
      </BikesContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Bold = styled.span`
  font-family: Termina-Bold, sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 12px;
`;

const BikesContainer = styled(Grid)`
  flex-grow: 1;
  max-width: 900px;
  width: 100%;
`;

const Bike = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BikeImage = styled.img`
  width: 100%;
  max-height: 268px;
  max-width: 168px;
  padding: 25px;
`;
