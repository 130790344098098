import firebase from 'firebase';

import {manualAppointmentsWebsocket} from '../firebase/firestoreCollections';

export const acceptDemand = (demandId: string, repairerUid: string) => {
  return firebase
    .firestore()
    .collection(manualAppointmentsWebsocket)
    .doc(demandId)
    .update({
      acceptedByRepairerUids: firebase.firestore.FieldValue.arrayUnion(
        repairerUid,
      ),
    });
};

export const refuseDemand = (demandId: string, repairerUid: string) => {
  return firebase
    .firestore()
    .collection(manualAppointmentsWebsocket)
    .doc(demandId)
    .update({
      refusedByRepairerUids: firebase.firestore.FieldValue.arrayUnion(
        repairerUid,
      ),
    });
};

export const finallyRefuseDemand = (demandId: string, repairerUid: string) => {
  return firebase
    .firestore()
    .collection(manualAppointmentsWebsocket)
    .doc(demandId)
    .update({
      refusedByRepairerUids: firebase.firestore.FieldValue.arrayUnion(
        repairerUid,
      ),
      acceptedByRepairerUids: firebase.firestore.FieldValue.arrayRemove(
        repairerUid,
      ),
    });
};
