export default async function client(
  endpoint,
  {body, ...customConfig} = {body: null},
) {
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      // @ts-ignore
      ...customConfig.headers,
    },
  };

  if (body) {
    // @ts-ignore
    config.body = JSON.stringify(body);
  }
  const response = await fetch(endpoint, config);
  return response.json();
}
