import firebase from 'firebase';
import uuid from 'uuid';

import {reparations} from '../firebase/firestoreCollections';

export const createReparation = async reparation => {
  const uid = uuid.v4();

  await firebase
    .firestore()
    .collection(reparations)
    .doc(uid)
    .set({
      uid,
      creationDate: new Date(),
      ...reparation,
    });

  return uid;
};

export const updateReparation = (reparationUid: string, newData: any) => {
  return firebase
    .firestore()
    .collection(reparations)
    .doc(reparationUid)
    .update(newData);
};

export const getMyReparations = async repairerUid => {
  const response = await firebase
    .firestore()
    .collection(reparations)
    .where('repairerUid', '==', repairerUid)
    .get();

  return response.docs.map(d => d.data());
};

export const getMyReparationsSnapshot = async (repairerUid, callback) => {
  if (!repairerUid) return [];

  return firebase
    .firestore()
    .collection(reparations)
    .where('repairerUid', '==', repairerUid)
    .onSnapshot(querySnapshot => querySnapshot.docChanges().forEach(callback));
};
