import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

export default () => {
  return (
    <Footer>
      <Social>
        <Bulle
          style={{
            backgroundImage: `url(${require(`../../assets/instagram.svg`)})`,
            backgroundSize: '70%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          href="https://www.instagram.com/biket.fr/"
          target="_blank"
          rel="noreferrer"
        />
        <Bulle
          style={{
            backgroundImage: `url(${require(`../../assets/facebook.svg`)})`,
            backgroundSize: '30%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          href="https://www.facebook.com/Biket-101389738661942/"
          target="_blank"
          rel="noreferrer"
        />
      </Social>
      <Logo />
      <Links>
        <span>Copyright © {new Date().getFullYear()}</span> -
        <Stylelink to="/politique-de-confidentialite">
          Politique de confidentialité
        </Stylelink>{' '}
        -<Stylelink to="/cgu">Conditions générales</Stylelink>
      </Links>
    </Footer>
  );
};

const Footer = styled.div`
  height: 255px;
  width: 100%;
  background-color: #00d18b;
  color: #ffffff;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Social = styled.div`
  display: flex;
  gap: 1rem;
`;
const Bulle = styled.a`
  background-color: #181f45;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;
const Logo = styled.div`
  width: 125px;
  min-height: 60px;
  background-image: url(${require('../../assets/biket_white.svg')});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 3rem;
`;
const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-top: 2rem;
  }
`;

const Stylelink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
