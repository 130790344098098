export default {
  home: '/',
  accountSettings: '/accountSettings',
  login: '/login',
  createAccount: '/create-account',
  confidentiality: '/politique-de-confidentialite',
  termsOfService: '/cgu',
  marketPlace: '/marketPlace',
  updateAdvertismentWithoutParam: '/updateAdvertisment/',
  updateAdvertisment: '/updateAdvertisment/:saleCardUid',
};
