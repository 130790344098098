import {useEffect, useState} from 'react';
import User from '../users/User.interface';
import firebase from 'firebase';

const useAuth = (): User | null | undefined => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null | undefined>();

  useEffect(() => {
    const onAuthStateChanged = newUser => {
      setUser(newUser);
      setInitializing(false);
    };

    return firebase.auth().onAuthStateChanged(onAuthStateChanged);
  }, [initializing]);

  if (initializing) return null;

  return user;
};

export default useAuth;
