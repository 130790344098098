import firebase from 'firebase';
import {createUser} from '../users/usersActions';

export const createNewUser = async (data: {
  email: string;
  firstName: string;
  lastName: string;
}) => {
  const repairer = firebase.auth().currentUser?.toJSON();

  const createUserWithAuth = firebase
    .functions()
    .httpsCallable('createUserWithAuth');
  const sendEmailGmail = firebase.functions().httpsCallable('sendEmailGmail');
  const password = (Math.random() + 1).toString(36).substring(2);

  const newUser = await createUserWithAuth({email: data.email, password}).then(
    result => {
      return {
        uid: result.data.uid,
        email: data.email,
        firstName: data.firstName ? data.firstName : '',
        lastName: data.lastName ? data.lastName : '',
        verified: false,
      };
    },
  );
  await createUser(newUser);

  await sendEmailGmail({
    user: {...newUser, password},
    repairer,
    type: 'create',
  }).then(result => {
    console.log(result);
  })
    .catch((error) => console.error(`CATCH sendEmailGmail() in ${createNewUser.name}():`,error));

  return newUser;
};
