import React from 'react';
import styled from 'styled-components';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Storefront from '@material-ui/icons/Storefront';
import firebase from 'firebase';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

import colors from '../theme/colors';
import routes from './routes';

export const MENU_WIDTH = '84px';

export default () => {
  const history = useHistory();

  const logout = async () => {
    try {
      await firebase.auth().signOut();
      history.push(routes.login);
    } catch (e) {}
  };

  return (
    <Menu>
      <MenuContent>
        <TopIconsLink to={routes.home}>
          <DateRangeIcon fontSize="large" />
          <Label>Agenda</Label>
        </TopIconsLink>

        <TopIconsLink to={routes.marketPlace}>
          <Storefront fontSize="large" />
          <Label>Vente</Label>
        </TopIconsLink>

        <TopIconsLink to={routes.accountSettings}>
          <Settings fontSize="large" />
          <Label>Paramètres</Label>
        </TopIconsLink>
      </MenuContent>

      <LogoutContainer onClick={logout}>
        <ExitToAppIcon fontSize="large" />
      </LogoutContainer>
    </Menu>
  );
};

const Menu = styled.div`
  width: ${MENU_WIDTH};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopIconsLink = styled(Link)`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin-bottom: 24px;
`;

const Label = styled.span`
  font-size: 15px;
`;

const Settings = styled(SettingsIcon)``;

const MenuContent = styled.div`
  height: 92%;
  width: ${MENU_WIDTH};
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`;

const LogoutContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: white;
  cursor: pointer;
`;
